import React from 'react';
import { useLocation } from 'react-router-dom';

import i18n from '@/translate/i18n';
import { NavLink } from '@/components/atoms/Typography/styles';
import { Content, LinksWrapper, Logo, Wrapper } from './styles';

function Footer() {
  const location = useLocation();

  return (
    <Wrapper>
      <Content>
        <Logo />
        <LinksWrapper>
          <NavLink
            to={{
              pathname: '/terms-and-conditions',
              search: location.search,
            }}
            activeClassName="navlink-active"
          >
            {i18n.t('footer.termsAndConditions')}
          </NavLink>
          <NavLink
            to={{
              pathname: '/privacy-policy',
              search: location.search,
            }}
            activeClassName="navlink-active"
          >
            {i18n.t('footer.privacyPolicy')}
          </NavLink>
        </LinksWrapper>
      </Content>
    </Wrapper>
  );
}

export default Footer;
