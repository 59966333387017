import { api } from './config';

export const getTransactionData = (id, token) =>
  api.get(`/merchant/checkout/${id}/${token}`).then((res) => res.data);

export const confirmTransaction = (id, token, payload) =>
  api
    .patch(`/merchant/checkout/${id}/${token}`, payload)
    .then((res) => res.data);

export const checkKycToken = (params) =>
  api
    .get('/merchant/customers/kyc/check-token', { params })
    .then((res) => res.data);

export const registerOcr = (payload) =>
  api
    .post('/merchant/customers/kyc/ocr/register', payload)
    .then((res) => res.data);

export const uploadDocument = (payload) =>
  api.post('/merchant/customers/documents', payload).then((res) => res.data);

export const setCustomerDocument = (transactionId, transactionToken, payload) =>
  api
    .patch(
      `/merchant/checkout/${transactionId}/${transactionToken}/customer-document`,
      payload,
    )
    .then((res) => res.data);

export const submitAcceptTerms = (transactionId, transactionToken) =>
  api
    .patch(
      `/merchant/checkout/${transactionId}/${transactionToken}/accept-terms`,
    )
    .catch((err) => err.response);

export const getOcrProvider = (params) =>
  api
    .get('/merchant/customers/kyc/ocr/provider', { params })
    .then((res) => res.data);

export const startOcrValidation = (payload) =>
  api
    .post('/merchant/customers/kyc/ocr/start', payload)
    .then((res) => res.data);
