import styled from 'styled-components';
import { Field } from 'formik';

export const InputStyled = styled(Field)`
  -moz-appearance: textfield; //hide arrows in firefox
  background-color: ${({ theme }) => theme.colors.bg.secondary};
  border: none;
  color: ${({ theme }) => theme.colors.info.secondary};
  font: ${({ theme }) =>
    `${theme.font.size.smallCalc} ${theme.font.family.primary}`};
  height: 4.5vh;
  margin: 1rem 0 0.8rem;
  max-height: 50px;
  max-width: 332px;
  min-height: 40px;
  min-width: 155px;
  padding: 0;
  text-align: center;
  width: ${({ width }) => width || '60vw'};

  &::placeholder {
    color: ${({ theme }) => theme.colors.info.secondary};
  }

  &:disabled {
    background-color: transparent;
  }

  &::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  option {
    background-color: ${({ theme }) => theme.colors.bg.primary};
  }
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 1vh 0;
  position: relative;
`;

export const Label = styled.label`
  color: ${({ theme, error }) =>
    error ? theme.colors.danger : theme.colors.info.primary};
  font-size: ${({ theme }) => theme.font.size.xSmall};
  font-weight: 600;
  position: absolute;
  text-transform: uppercase;
  top: -5px;
`;

export const ErrorText = styled.div`
  bottom: -5px;
  color: ${({ theme }) => theme.colors.danger};
  font-size: ${({ theme }) => theme.font.size.xSmall};
  position: absolute;
`;
