import React from 'react';

import { Loader, Wrapper } from './styles';

function CircularLoader() {
  return (
    <Wrapper>
      <Loader />
    </Wrapper>
  );
}

export default CircularLoader;
