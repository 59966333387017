import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import io from 'socket.io-client';

import AcceptanceOfTerms from '@/components/molecules/Modal/Content/AcceptanceOfTerms';
import CopyButton from '@/components/atoms/Buttons/CopyButton';
import Footer from '@/components/molecules/Footer';
import Header from '@/components/molecules/Header';
import Modal from '@/components/molecules/Modal';
import i18n from '@/translate/i18n';
import { Container, Main } from '@/components/atoms/Containers/styles';
import { Paragraph, Title } from '@/components/atoms/Typography/styles';
import { convertMsToTime, copyTextToClipboard, formatCurrency } from '@/utils';
import { useTransactionData } from '@/context/transactionContext';

import {
  ContentWrapper,
  PixIcon,
  QrCode,
  QRCodePlaceholder,
  RedirectButton,
  Section,
  Span,
  SuccessMessage,
  TextWrapper,
} from './styles';

const ONE_SECOND = 1000;
const TEN_MINUTES = 600000;
const THREE_SECONDS = 3000;

function TransactionDetails() {
  const history = useHistory();
  const { id, token } = useParams();
  const { transactionData } = useTransactionData();

  if (!transactionData?.id) {
    history.push(`/${id}/${token}${history.location.search}`);
  }

  const initialTimerState = transactionData?.seconds_to_expire
    ? transactionData.seconds_to_expire * ONE_SECOND
    : TEN_MINUTES;

  const termsAlreadyAccepted = Boolean(
    transactionData?.customer?.terms_accepted_at,
  );

  const [counter, setCounter] = useState(initialTimerState);
  const [showTermsModal, setShowTermsModal] = useState(!termsAlreadyAccepted);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SERVER_URL, {
      path: '/websocket/socket.io',
    });

    socket.on('confirmTransaction', (socketData) => {
      if (socketData.id === Number(id)) {
        socket.disconnect();
        if (socketData?.kyc_required) {
          window.location.replace(socketData.redirect_url);
        } else if (socketData?.status !== 'pending') {
          history.push({
            pathname: `/deposit/status/${id}/${token}`,
            search: history.location.search,
            state: {
              data: socketData,
            },
          });
        }
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [id, history, token]);

  useEffect(() => {
    const timer = setInterval(
      () => setCounter(counter - ONE_SECOND),
      ONE_SECOND,
    );
    return () => clearInterval(timer);
  }, [counter]);

  const isTimerRunning = counter > 0;

  const onSuccessfullyCopied = () => {
    setShowSuccessMessage(true);

    setTimeout(() => {
      setShowSuccessMessage(false);
    }, THREE_SECONDS);
  };

  return (
    <Container>
      <Main>
        <Header title={i18n.t('header.previous')} />
        <ContentWrapper>
          <Section>
            <PixIcon />
            <Span>
              {formatCurrency(
                transactionData?.merchant_request_amount,
                transactionData?.merchant_request_currency_iso,
                true,
              )}
            </Span>
          </Section>
          {showTermsModal ? (
            <QRCodePlaceholder />
          ) : (
            <QrCode value={transactionData.pix_code} alt="QR Code" />
          )}
          {showSuccessMessage && (
            <SuccessMessage>
              {i18n.t('transactionDetails.successfullyCopied')}
            </SuccessMessage>
          )}
          {isTimerRunning && (
            <TextWrapper>
              <Paragraph>{i18n.t('transactionDetails.description')}</Paragraph>
              <Paragraph size="medium">{convertMsToTime(counter)}</Paragraph>
            </TextWrapper>
          )}
          {!showTermsModal && (
            <CopyButton
              content={i18n.t('transactionDetails.pixButton')}
              handleClick={() =>
                copyTextToClipboard(
                  transactionData?.pix_code,
                  onSuccessfullyCopied,
                )
              }
            />
          )}

          <TextWrapper>
            <Title size="base">
              {i18n.t('transactionDetails.instructionsTitle')}
            </Title>
            <Paragraph>
              <Trans
                i18n={i18n}
                i18nKey="transactionDetails.instructionsContent"
                components={[<strong />]}
              />
            </Paragraph>
          </TextWrapper>
          {transactionData?.redirect_url_success && (
            <RedirectButton
              type="button"
              onClick={() => {
                window.location.href = transactionData.redirect_url_success;
              }}
            >
              {i18n.t('transactionStatus.return')}
            </RedirectButton>
          )}
          {showTermsModal && (
            <Modal
              setShowModal={setShowTermsModal}
              maxWidth="400px"
              removeCloseBtn
              height="350px"
            >
              <AcceptanceOfTerms closeModal={() => setShowTermsModal(false)} />
            </Modal>
          )}
        </ContentWrapper>
        <Footer />
      </Main>
    </Container>
  );
}

export default TransactionDetails;
