import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory, useParams } from 'react-router-dom';

import Button from '@/components/atoms/Buttons/Button';
import CaptureImage from '@/components/molecules/CaptureImage';
import CircularLoader from '@/components/atoms/CircularLoader';
import DragAndDrop from '@/components/molecules/DragAndDrop';
import ErrorMessage from '@/components/atoms/ErrorMessage';
import Footer from '@/components/molecules/Footer';
import Header from '@/components/molecules/Header';
import SuccessMessageDefault from '@/components/atoms/SuccessMessageDefault';
import i18n from '@/translate/i18n';
import { Container, Main } from '@/components/atoms/Containers/styles';
import { ContentWrapper, HiddenInputFile } from './styles';
import { Paragraph, Title } from '@/components/atoms/Typography/styles';
import { checkKycToken, uploadDocument } from '@/services/api';
import { convertToBase64 } from '@/utils';

const TIME_TO_REDIRECT = 3000;

function SOFValidationUpload() {
  const inputRef = useRef(null);
  const history = useHistory();
  const { kycLevel, customerId, token: apiToken } = useParams();

  const [error, setError] = useState('');
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();

      formData.append('file', file);
      formData.append('customer_id', customerId);
      formData.append('token', apiToken);
      formData.append('verification_type', 'sof');

      await uploadDocument(formData);

      setShowSuccessMessage(true);
      setTimeout(() => {
        history.push(
          `/kyc/${kycLevel}/customer/${customerId}/${apiToken}/address`,
        );
      }, TIME_TO_REDIRECT);
    } catch (err) {
      const message = err?.response?.data?.message;
      setError(i18n.t([`error.kyc.${message}`, 'error.kyc.unspecific']));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const checkToken = async () => {
      try {
        setIsLoading(true);
        const params = {
          kyc_level: kycLevel,
          customer_id: customerId,
          token: apiToken,
        };
        await checkKycToken(params);
      } catch (err) {
        const message = err?.response?.data?.message;
        setError(i18n.t([`error.${message}`, 'error.unspecific']));
      } finally {
        setIsLoading(false);
      }
    };

    checkToken();
  }, [apiToken, customerId, kycLevel]);

  return (
    <Container>
      <Main>
        <Header defaultLogo />
        {error ? (
          <ErrorMessage>{error}</ErrorMessage>
        ) : isLoading ? (
          <CircularLoader />
        ) : showSuccessMessage ? (
          <SuccessMessageDefault
            message={i18n.t('sofValidation.successMessage')}
          />
        ) : (
          <ContentWrapper>
            <div>
              <Title>{i18n.t('sofValidation.proofOfIncome')}</Title>
              <Paragraph>{i18n.t('sofValidation.uploadDescription')}</Paragraph>
            </div>
            {isMobile ? (
              <CaptureImage
                title={i18n.t('sofValidation.takeAPicture')}
                setFile={setFile}
              />
            ) : (
              <DragAndDrop
                title={i18n.t('sofValidation.dragAndDrop')}
                setFile={setFile}
                file={file}
                changeFile={() => inputRef.current.click()}
                fileUrl={fileUrl}
              />
            )}
            <Button
              type="button"
              handleClick={file ? handleSubmit : () => inputRef.current.click()}
            >
              {file
                ? i18n.t('sofValidation.sendFile')
                : i18n.t('sofValidation.selectFile')}
            </Button>
            <HiddenInputFile
              type="file"
              ref={inputRef}
              accept="image/*, application/pdf"
              onChange={async (e) => {
                setFile(e.target.files[0]);
                const url = await convertToBase64(e.target.files[0]);
                setFileUrl(url);
              }}
            />
          </ContentWrapper>
        )}
        <Footer />
      </Main>
    </Container>
  );
}

export default SOFValidationUpload;
