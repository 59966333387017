import styled from 'styled-components';
import { ReactComponent as ArrowIcon } from '@/assets/images/arrow.svg';

export const Button = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  display: flex;
  gap: 0.4rem;
  margin: 0 1rem;

  &:hover {
    h1 {
      color: ${({ theme }) => theme.colors.info.light};
    }

    path {
      fill: ${({ theme }) => theme.colors.info.light};
    }
  }
`;

export const Arrow = styled(ArrowIcon)`
  path {
    fill: ${({ theme }) => theme.colors.info.secondary};
  }
`;
