import styled, { css } from 'styled-components';

import { ReactComponent as ClockImage } from '@/assets/images/clock.svg';
import { ReactComponent as ErrorImage } from '@/assets/images/error.svg';

const IconStyles = css`
  fill: ${({ theme }) => theme.colors.divider};
  height: 100px;
  margin-bottom: 1rem;
  width: 100px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 80%;
  justify-content: space-between;
  padding: 2rem 0;

  div {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
  }
`;

export const ClockIcon = styled(ClockImage)`
  ${IconStyles}
`;

export const ErrorIcon = styled(ErrorImage)`
  ${IconStyles}
`;
