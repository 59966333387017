import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import App from './App';

const replaceHashPath = () => {
  const history = createBrowserHistory();
  const { hash } = history.location;
  if (hash) {
    const path = (/#!(\/.*)$/.exec(hash) || [])[1];
    if (path) {
      history.replace(path);
    }
  }
};
replaceHashPath();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);
