import axios from 'axios';

const tokenMock = 656565;

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_GATEWAY,
});

const setHeaders = (config) => {
  config.headers.Authorization = `Bearer ${tokenMock}`;
  return config;
};

const rejectPromise = (error) => Promise.reject(error);

api.interceptors.request.use(setHeaders, rejectPromise);
