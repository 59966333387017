import styled from 'styled-components';

export const Loader = styled.span`
  animation: rotation 1s linear infinite;
  border: 5px solid ${({ theme }) => theme.colors.divider};
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  height: 48px;
  margin: 40% 0;
  width: 48px;
`;

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto 0;
`;
