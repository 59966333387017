import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { registerOcr } from '@/services/api';
import i18n from '@/translate/i18n';

import { IdwallContainer } from './styles';

function IdwallSdk({ setError, setShowSuccessMessage }) {
  const { customerId, token: apiToken } = useParams();
  useEffect(() => {
    const idwSDK = window.idwSDKWeb;
    idwSDK({
      token: process.env.REACT_APP_IDW_SDK_TOKEN,
      onComplete: async ({ token }) => {
        try {
          const payload = {
            customer_id: customerId,
            token: apiToken,
            provider: 'idwall',
            number: token,
          };
          await registerOcr(payload);
          setShowSuccessMessage(true);
        } catch (err) {
          const message = err?.response?.data?.message;
          setError(i18n.t([`error.kyc.${message}`, 'error.kyc.unspecific']));
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiToken, customerId]);

  return (
    <IdwallContainer>
      <div data-idw-sdk-web />
    </IdwallContainer>
  );
}

IdwallSdk.propTypes = {
  setError: PropTypes.func,
  setShowSuccessMessage: PropTypes.func,
};

export default IdwallSdk;
