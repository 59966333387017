import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import Button from '@/components/atoms/Buttons/Button';
import CircularLoader from '@/components/atoms/CircularLoader';
import { Container, Main } from '@/components/atoms/Containers/styles';
import ErrorMessage from '@/components/atoms/ErrorMessage';
import SuccessMessageDefault from '@/components/atoms/SuccessMessageDefault';
import { Paragraph, Title } from '@/components/atoms/Typography/styles';
import DragAndDrop from '@/components/molecules/DragAndDrop';
import Footer from '@/components/molecules/Footer';
import Header from '@/components/molecules/Header';
import { uploadDocument } from '@/services/api';
import i18n from '@/translate/i18n';
import { convertToBase64 } from '@/utils';

import { ContentWrapper, HiddenInputFile } from './styles';

const FILE_TYPES = ['pdf', 'jpg', 'jpeg', 'png', 'bmp', 'svg'];

function UploadProofOfAddress() {
  const inputRef = useRef(null);
  const { customerId, token: apiToken } = useParams();

  const [error, setError] = useState('');
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [invalidFile, setInvalidFile] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const payload = {
        file,
        customer_id: customerId,
        verification_type: 'address',
        token: apiToken,
      };
      const formData = new FormData();

      Object.entries(payload).forEach(([key, value]) => {
        formData.append(key, value);
      });

      await uploadDocument(formData);
      setShowSuccessMessage(true);
    } catch (err) {
      const message = err?.response?.data?.message;
      setError(i18n.t([`error.kyc.${message}`, 'error.kyc.unspecific']));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (file) {
      const fileExt = file?.type?.split('/')[1];

      if (!FILE_TYPES.includes(fileExt)) {
        setInvalidFile(true);
        setFile(null);
        setFileUrl('');
      } else {
        setInvalidFile(false);
      }
    }
  }, [file]);

  return (
    <Container>
      <Main>
        <Header defaultLogo />
        {error ? (
          <ErrorMessage>{error}</ErrorMessage>
        ) : isLoading ? (
          <CircularLoader />
        ) : showSuccessMessage ? (
          <SuccessMessageDefault
            message={i18n.t('idValidation.successMessage')}
            messageDescription={i18n.t('idValidation.successDescription')}
          />
        ) : (
          <ContentWrapper>
            <div>
              <Title>{i18n.t('addressUpload.title')}</Title>
              <Paragraph>{i18n.t('addressUpload.description')}</Paragraph>
            </div>
            <DragAndDrop
              title={i18n.t('addressUpload.dragAndDrop')}
              setFile={setFile}
              file={file}
              changeFile={() => inputRef.current.click()}
              fileUrl={fileUrl}
            />
            {invalidFile && (
              <Paragraph color="danger">
                {i18n.t('addressUpload.selectValidDocument')}
                {FILE_TYPES.map((ext, idx) => (
                  <span key={ext}>
                    {ext} {idx < FILE_TYPES.length ? ', ' : ''}
                  </span>
                ))}
              </Paragraph>
            )}
            <Button
              type="button"
              handleClick={file ? handleSubmit : () => inputRef.current.click()}
            >
              {file
                ? i18n.t('addressUpload.sendFile')
                : i18n.t('addressUpload.selectFile')}
            </Button>
            <HiddenInputFile
              type="file"
              ref={inputRef}
              accept="image/*, application/pdf"
              onChange={async (e) => {
                setFile(e.target.files[0]);
                const url = await convertToBase64(e.target.files[0]);
                setFileUrl(url);
              }}
            />
          </ContentWrapper>
        )}
        <Footer />
      </Main>
    </Container>
  );
}

export default UploadProofOfAddress;
