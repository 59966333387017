import React from 'react';
import PropTypes from 'prop-types';

import MerchantLogo from '@/components/atoms/MerchantLogo';
import ReturnButton from '@/components/atoms/Buttons/ReturnButton';
import { useTransactionData } from '@/context/transactionContext';

import {
  Container,
  Content,
  LogoWrapper,
  TitleWrapper,
  VPagLogo,
  Wrapper,
} from './styles';

function Header({ handleClick, title, defaultLogo }) {
  const { transactionData } = useTransactionData();

  return (
    <Container>
      <Wrapper>
        <Content>
          {handleClick && (
            <TitleWrapper>
              <ReturnButton handleClick={handleClick} title={title} />
            </TitleWrapper>
          )}
          <LogoWrapper>
            {defaultLogo ? (
              <VPagLogo />
            ) : (
              <MerchantLogo
                slug={transactionData?.merchant?.slug}
                name={transactionData?.merchant?.name}
              />
            )}
          </LogoWrapper>
        </Content>
      </Wrapper>
    </Container>
  );
}

Header.propTypes = {
  handleClick: PropTypes.func,
  title: PropTypes.string,
  defaultLogo: PropTypes.bool,
};

export default Header;
