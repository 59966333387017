import React from 'react';
import PropTypes from 'prop-types';

import { HideOnMobile } from '@/components/atoms/HideOnMobile/styles';
import { Title } from '@/components/atoms/Typography/styles';

import { Arrow, Button } from './styles';

function ReturnButton({ handleClick, title }) {
  return (
    <Button type="button" onClick={handleClick}>
      <Arrow />
      <HideOnMobile>
        <Title color="secondary">{title}</Title>
      </HideOnMobile>
    </Button>
  );
}

ReturnButton.propTypes = {
  handleClick: PropTypes.func,
  title: PropTypes.string,
};

export default ReturnButton;
