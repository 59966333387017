import styled from 'styled-components';

import { ReactComponent as ErrorImage } from '@/assets/images/error.svg';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  height: 75%;
  justify-content: space-between;
  padding: 1rem 0;
`;

export const ErrorIcon = styled(ErrorImage)`
  fill: ${({ theme }) => theme.colors.divider};
  height: 80px;
  margin-bottom: 1rem;
  width: 80px;
`;
