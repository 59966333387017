import * as yup from 'yup';
import { validateCPF } from 'validations-br';

import i18n from '@/translate/i18n';

export const brDateRegex =
  /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i;

export const userDataSchema = yup.object().shape({
  document_number: yup
    .string()
    .required(i18n.t('form.cpfRequired'))
    .test('validate-cpf', i18n.t('form.invalidCpf'), (value) =>
      validateCPF(value),
    ),
  email: yup
    .string()
    .email(i18n.t('form.invalidEmail'))
    .required(i18n.t('form.emailRequired')),
  merchant_request_amount: yup
    .number()
    .min(1, i18n.t('form.minAmount', { value: 1, currency: 'BRL' }))
    .required(i18n.t('form.amountRequired', { value: 3 })),
  accepted_terms: yup
    .bool()
    .oneOf([true], i18n.t('form.acceptedTermsRequired'))
    .required(),
});

export const cpfSchema = yup.object().shape({
  document_number: yup
    .string()
    .required(i18n.t('form.cpfRequired'))
    .test('validate-cpf', i18n.t('form.invalidCpf'), (value) =>
      validateCPF(value),
    ),
});

export const addressSchema = yup.object().shape({
  address: yup.string().required(i18n.t('addressForm.addressRequired')),
  postal_code: yup.string().required(i18n.t('addressForm.postalCodeRequired')),
  state: yup.string().required(i18n.t('addressForm.stateRequired')),
  city: yup
    .string()
    .min(3, i18n.t('addressForm.invalidCity'))
    .required(i18n.t('addressForm.cityRequired')),
});

export const pixKeySchema = yup.object().shape({
  accepted_terms: yup
    .bool()
    .oneOf([true], i18n.t('form.acceptedTermsRequired')),
});

export const pixKeyWithTermsSchema = pixKeySchema.shape({
  pix_type: yup.string().required(i18n.t('form.pixTypeRequired')),
  pix_key: yup
    .string()
    .required(i18n.t('form.pixKeyRequired'))
    .when('pix_type', (pix_type, schema) => {
      if (pix_type === 'cpf') {
        return schema.test('pix_key', i18n.t('form.invalidCpf'), (value) =>
          validateCPF(value),
        );
      }

      if (pix_type === 'email') {
        return schema.email(i18n.t('form.invalidEmail'));
      }

      if (pix_type === 'phone') {
        return schema
          .min(11, i18n.t('form.invalidPhoneNumber'))
          .max(11, i18n.t('form.invalidPhoneNumber'));
      }
      return schema;
    }),
});

export const validateSchema = async (data, schema) =>
  schema.isValid(data).then((validation) => validation);

export const validateAndRedirect = async (data, callback) => {
  const isValidAddress = await validateSchema(data?.customer, addressSchema);
  const isValidUserData = await validateSchema(
    {
      ...data.customer,
      merchant_request_amount: data?.merchant_request_amount,
      accepted_terms: Boolean(data?.terms_accepted_at),
    },
    userDataSchema,
  );

  if (isValidUserData && !isValidAddress) {
    callback(true);
  }
};

export const addressRequiredOnly = yup.object().shape({
  address: yup.string().required(i18n.t('addressForm.addressRequired')),
  postal_code: yup.string().required(i18n.t('addressForm.postalCodeRequired')),
  state: yup.string().required(i18n.t('addressForm.stateRequired')),
  city: yup.string().required(i18n.t('addressForm.cityRequired')),
});

export const userDataRequiredOnly = yup.object().shape({
  document_number: yup.string().required(i18n.t('form.cpfRequired')),
  email: yup.string().required(i18n.t('form.emailRequired')),
  merchant_request_amount: yup
    .number()
    .required(i18n.t('form.amountRequired', { value: 3 })),
  accepted_terms: yup
    .bool()
    .oneOf([true], i18n.t('form.acceptedTermsRequired'))
    .required(),
});
