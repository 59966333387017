import styled from 'styled-components';

import { ReactComponent as Camera } from '@/assets/images/camera.svg';

export const Wrapper = styled.div``;

export const CamIcon = styled(Camera)`
  path {
    fill: ${({ theme }) => theme.colors.divider};
  }
  rect {
    stroke: ${({ theme }) => theme.colors.divider};
  }
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.info.secondary};
  font-size: ${({ theme }) => theme.font.size.small};
  margin-top: 2rem;
  text-transform: uppercase;
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const Button = styled.button`
  background-color: transparent;
  border: none;
`;
