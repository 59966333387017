import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;

  label {
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    cursor: pointer;
    display: block;
    font-size: 22px;
    margin-bottom: 20px;
    position: relative;
    user-select: none;
  }

  label input {
    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;
  }

  span {
    background-color: transparent;
    border: solid 2px
      ${({ theme, error }) =>
        error ? theme.colors.danger : theme.colors.checkbox.primary};
    border-radius: 4px;
    height: 18px;
    left: 0;
    position: absolute;
    top: 0;
    width: 18px;
  }

  label input:checked + span {
    background-color: ${({ theme }) => theme.colors.checkbox.primary};
  }

  label:hover input ~ span {
    filter: brightness(0.9);
  }

  span:after {
    content: '';
    display: none;
    position: absolute;
  }

  label input:checked ~ span:after {
    display: block;
  }

  label span:after {
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    border: solid ${({ theme }) => theme.colors.checkbox.secondary};
    border-width: 0 2px 2px 0;
    height: 10px;
    left: 4px;
    top: -1px;
    transform: rotate(45deg);
    width: 5px;
  }
`;
