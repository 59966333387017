import React from 'react';
import PropTypes from 'prop-types';

import { Input, InputWrapper } from './styles';
import { getCurrencySymbol } from '@/utils';
import { ErrorText } from '../Input/styles';

function InputAmount({
  currency,
  disabled,
  error,
  errorText,
  handleBlur,
  handleChange,
  id,
  value,
}) {
  return (
    <InputWrapper error={error} disabled={disabled}>
      {getCurrencySymbol('pt-BR', currency)}
      <Input
        id={id}
        name={id}
        onBlur={handleBlur}
        type="text"
        error={error}
        disabled={disabled}
        placeholder="0"
        pattern="[0-9]*"
        value={value}
        {...(handleChange ? { onChange: handleChange } : {})}
      />
      {errorText && <ErrorText>{errorText}</ErrorText>}
    </InputWrapper>
  );
}

InputAmount.propTypes = {
  currency: PropTypes.string,
  error: PropTypes.string,
  errorText: PropTypes.string,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  id: PropTypes.string,
  value: PropTypes.string,
}.isRequired;

export default InputAmount;
