import React from 'react';
import PropTypes from 'prop-types';

import { Button, Icon } from './styles';

function CopyButton({ content, handleClick }) {
  return (
    <Button type="button" onClick={handleClick}>
      <Icon />
      {content}
    </Button>
  );
}

CopyButton.propTypes = {
  content: PropTypes.string,
  handleClick: PropTypes.func,
}.isRequired;

export default CopyButton;
