import React from 'react';
import PropTypes from 'prop-types';

import { SucessIcon, Wrapper } from './styles';
import { Paragraph, Title } from '@/components/atoms/Typography/styles';

function SuccessMessageDefault({ message, messageDescription }) {
  return (
    <Wrapper>
      <div>
        <SucessIcon />
        <Title>{message}</Title>
        <Paragraph>{messageDescription}</Paragraph>
      </div>
    </Wrapper>
  );
}

SuccessMessageDefault.propTypes = {
  message: PropTypes.string,
  messageDescription: PropTypes.string,
};

export default SuccessMessageDefault;
