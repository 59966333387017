import React from 'react';
import PropTypes from 'prop-types';

import i18n from '@/translate/i18n';
import { Paragraph } from '@/components/atoms/Typography/styles';
import {
  Button,
  ImagePreview,
  Link,
  Text,
  UploadIcon,
  Wrapper,
} from './styles';

const images = ['png', 'jpg', 'jpeg'];

function DragAndDrop({ file, title, setFile, changeFile, fileUrl }) {
  const preventDefault = (e) => {
    e.preventDefault();
  };

  const fileDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target.files) {
      setFile(e.target.files[0]);
    } else {
      setFile(e.dataTransfer.files[0]);
    }
  };
  const fileExt = file?.type?.replaceAll('image/', '');

  return (
    <Wrapper
      onDragEnter={preventDefault}
      onDragLeave={preventDefault}
      onDragOver={preventDefault}
      onDrop={fileDrop}
    >
      {file && (
        <>
          {images.includes(fileExt) ? (
            <ImagePreview src={URL.createObjectURL(file)} alt="SOF" />
          ) : (
            <Link
              to={{ pathname: fileUrl }}
              target="_blank"
              rel="noopener noreferrer"
            >
              {i18n.t('sofValidation.previewDocument')}
            </Link>
          )}
          <Paragraph>{file?.name}</Paragraph>
          <Button type="button" onClick={changeFile}>
            {i18n.t('sofValidation.changeFile')}
          </Button>
        </>
      )}

      {!file && (
        <>
          <UploadIcon />
          <Text>{title ?? ''}</Text>
        </>
      )}
    </Wrapper>
  );
}

DragAndDrop.propTypes = {
  title: PropTypes.string,
  setFile: PropTypes.func,
}.isRequired;

export default DragAndDrop;
