import styled, { css } from 'styled-components';

import { NavLink as RouterNavLink } from 'react-router-dom';

export const Title = styled.h1`
  ${({ theme, size, color = 'primary' }) => css`
    color: ${theme.colors.info[color]};
    font: ${`${size ? theme.font.size[size] : theme.font.size.medium} ${
      theme.font.family.bold
    }`};
  `}
`;

export const Paragraph = styled.p`
  ${({ theme, size, color = 'primary' }) => css`
    color: ${theme.colors.info[color]};
    font: ${`${size ? theme.font.size[size] : theme.font.size.small} ${
      theme.font.family.primary
    }`};
    padding-left: ${({ indent }) => (indent ? '1.5rem' : 'none')};

    strong {
      font: ${`${size ? theme.font.size[size] : theme.font.size.small} ${
        theme.font.family.bold
      }`};
    }
  `}
`;

export const NavLink = styled(RouterNavLink)`
  color: ${({ theme }) => theme.colors.info.primary};
  font: ${({ theme }) =>
    `${theme.font.size.small} ${theme.font.family.primary}`};
  line-height: 200%;
  margin: 0 5px;

  &.${({ activeClassName }) => activeClassName} {
    color: ${({ theme }) => theme.colors.info.light};
    font-weight: 600;
  }
`;
