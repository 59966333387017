import { useHistory, useParams } from 'react-router-dom';

import i18n from '@/translate/i18n';
import { confirmTransaction } from '@/services/api';

function useSubmitData(setError, setIsFetching, setData) {
  const history = useHistory();
  const { id, token } = useParams();

  const submitData = async (data) => {
    const {
      accepted_terms,
      customer,
      merchant_request_amount,
      bank_amount,
      bank_payment_data,
    } = data;

    try {
      setIsFetching(true);
      const payload = {
        accepted_terms,
        merchant_amount: Number(merchant_request_amount || bank_amount),
        customer_data: {
          document_number: customer?.document_number,
          email: customer?.email,
        },
        ...(bank_payment_data?.pix_type && {
          payment_data: {
            pix_key: bank_payment_data?.pix_key,
            pix_type: bank_payment_data?.pix_type,
          },
        }),
      };

      const res = await confirmTransaction(id, token, payload);

      if (data?.type === 'deposit') {
        setData(res.data);
        history.push(`/transaction-details/${id}/${token}`);
      } else {
        history.push({
          pathname: `/withdrawal/status/${id}/${token}`,
          search: history.location.search,
          state: {
            data: res.data,
            origin: 'withdrawal',
          },
        });
      }
    } catch (err) {
      const message = err?.response?.data?.message;
      setError(i18n.t([`error.${message}`, 'error.unspecific']));
    } finally {
      setIsFetching(false);
    }
  };

  return { submitData };
}

export default useSubmitData;
