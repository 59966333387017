import React from 'react';
import PropTypes from 'prop-types';

import Button from '@/components/atoms/Buttons/Button';
import i18n from '@/translate/i18n';
import { ErrorIcon, Wrapper } from './styles';
import { Paragraph, Title } from '@/components/atoms/Typography/styles';

function ErrorMessage({ children, handleClick }) {
  return (
    <Wrapper>
      <Title>{i18n.t('error.title')}</Title>
      <div>
        <ErrorIcon />
        <Title>{children}</Title>
        <Paragraph>{i18n.t('error.tryAgain')}</Paragraph>
      </div>
      <Button
        type="submit"
        handleClick={() =>
          handleClick ? handleClick() : window.location.reload()
        }
      >
        {i18n.t('error.tryAgainButton')}
      </Button>
    </Wrapper>
  );
}

ErrorMessage.propTypes = {
  children: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
};

export default ErrorMessage;
