import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useParams } from 'react-router-dom';
import { SdkIframe } from './styles';
import { startOcrValidation } from '@/services/api';
import i18n from '@/translate/i18n';
import CircularLoader from '@/components/atoms/CircularLoader';

function IFrameOcr({ ocrProviderSlug, setError }) {
  const { customerId, token: apiToken } = useParams();
  const [iframeUrl, setIframeUrl] = useState('');
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    const getOcrProviderUrl = async () => {
      try {
        setIsFetching(true);
        const payload = {
          customer_id: customerId,
          token: apiToken,
          provider: ocrProviderSlug,
        };

        const res = await startOcrValidation(payload);

        setIframeUrl(res.data.url);
      } catch (err) {
        const message = err?.response?.data?.message;
        setError(i18n.t([`error.${message}`, 'error.unspecific']));
      } finally {
        setIsFetching(false);
      }
    };

    if (ocrProviderSlug) {
      getOcrProviderUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ocrProviderSlug]);

  return isFetching ? (
    <CircularLoader />
  ) : (
    <SdkIframe
      title={`${ocrProviderSlug} OCR`}
      src={iframeUrl}
      allow="camera;fullscreen;accelerometer;gyroscope;magnetometer;"
    />
  );
}
IFrameOcr.propTypes = {
  ocrProviderSlug: PropTypes.string,
  setIsFetching: PropTypes.func,
  setError: PropTypes.func,
}.isRequired;
export default IFrameOcr;
