import styled, { css } from 'styled-components';

import QRCode from 'react-qr-code';
import { ReactComponent as Pix } from '@/assets/images/pix-logo.svg';
import { Button } from '@/components/atoms/Buttons/CopyButton/styles';

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  justify-content: space-evenly;
  width: 100%;
`;

export const TextWrapper = styled.div`
  display: ${({ hideInMobile }) => (hideInMobile ? 'none' : 'block')};
  justify-self: start;
  max-width: 80%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    & {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      max-width: 410px;
    }
  }
`;

export const Section = styled.section`
  align-items: center;
  color: ${({ theme }) => theme.colors.info.secondary};
  display: flex;
  flex-direction: column;
  font: ${({ theme }) => `${theme.font.size.small} ${theme.font.family.bold}`};
  justify-content: center;
  max-height: 170px;
  min-width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    & {
      flex-direction: row;
      max-height: auto;
      min-height: auto;
    }
  }
`;

export const Span = styled.span`
  font: ${({ theme }) =>
    `${theme.font.size.xLarge} ${theme.font.family.primary}`};
  margin-top: 0.5rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    & {
      margin: 0 0 0 3rem;
    }
  }
`;

export const PixIcon = styled(Pix)`
  height: 20%;
  max-height: 40px;
  max-width: 130px;
  min-width: 100px;
  width: 35%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    & {
      height: auto;
      max-height: auto;
      max-width: 82px;
      min-width: 60px;
      width: 15%;
    }
  }
`;

export const QrCode = styled(QRCode)`
  border: 5px solid white;
  border-radius: 5px;
  height: 100%;
  margin: 1rem 0;
  max-height: 175px;
  max-width: 175px;
  min-width: 135px;
  width: 100%;
`;

export const QRCodePlaceholder = styled.div`
  background: ${({ theme }) =>
    `linear-gradient(to right, ${theme.colors.divider} 30%,${theme.colors.bg.secondary})`};
  border-radius: 5px;
  height: 150px;
  width: 150px;
`;

export const SuccessMessage = styled.p`
  color: ${({ theme }) => theme.colors.success};
`;

export const QrCodeText = styled.p`
  background-color: ${({ theme }) => theme.colors.bg.secondary};
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.divider};
  color: ${({ theme }) => theme.colors.info.primary};
  font-size: ${({ theme }) => theme.font.size.small};
  max-width: 300px;
  padding: 1rem;
  width: 80%;
  word-wrap: break-word;
`;

export const RedirectButton = styled(Button)`
  ${({ theme }) => css`
    color: ${theme.colors.info.highlight};
    height: auto;
    padding: 0.4rem 0.8rem 0.3rem 0.8rem;
  `}
`;
