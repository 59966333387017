import styled from 'styled-components';

import { ReactComponent as Vpag } from '@/assets/images/vpag-logo.svg';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.bg.contrast};
  height: 55px;
  max-width: 595px;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 99999;

  @media screen and (min-height: ${({ theme }) => theme.breakpoints.h.md}) {
    & {
      position: absolute;
    }
  }
`;

export const Wrapper = styled.header`
  background-color: ${({ theme }) => theme.colors.header.bg};
  display: flex;
  height: 55px;
  max-width: 595px;
  padding: 1rem;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    & {
      border-radius: 20px 20px 0 0;
      padding: 1vh 3vw;
    }
  }

  @media screen and (min-height: ${({ theme }) => theme.breakpoints.h.md}) {
    & {
      position: absolute;
    }
  }
`;

export const Content = styled.section`
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-width: 100%;
  position: relative;
`;

export const TitleWrapper = styled.div`
  display: flex;
  z-index: 3;
`;

export const LogoWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  z-index: 1;
`;

export const VPagLogo = styled(Vpag)`
  path {
    fill: ${({ theme }) => theme.colors.logoHeader};
  }
`;
