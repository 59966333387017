import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Trans } from 'react-i18next';

import AmountSelection from '@/components/molecules/AmountSelection';
import Button from '@/components/atoms/Buttons/Button';
import CheckBox from '@/components/atoms/Checkbox';
import Input from '@/components/atoms/Input';
import { FormStyled, InputWrapper } from '@/components/atoms/FormStyles/styles';
import { Paragraph } from '@/components/atoms/Typography/styles';
import useSubmitData from '@/hooks/useSubmitData';
import i18n from '@/translate/i18n';

import {
  CheckboxWrapper,
  ErrorText,
} from '@/components/organisms/UserDataForm/styles';

import {
  pixKeySchema,
  pixKeyWithTermsSchema,
  userDataRequiredOnly,
  validateSchema,
} from '@/utils/validations';

import {
  formatCpfMask,
  formatPhoneNumberMask,
  formatPixKeyString,
} from '@/utils';

const MAX_DIGITS = 6;

function PaymentDataForm({ data, setData, setError, setIsFetching }) {
  const history = useHistory();
  const { id, token } = useParams();
  const [customerDataValidated, setCustomerDataValidated] = useState(false);
  const { submitData } = useSubmitData(setError, setIsFetching);

  const pixTypeOptions = [
    { label: i18n.t('form.cpf'), value: 'cpf' },
    { label: i18n.t('form.email'), value: 'email' },
    { label: i18n.t('form.phone'), value: 'phone' },
  ];

  const requestContainsCpf = data?.customer?.document_number;
  const fieldValues = {
    cpf: requestContainsCpf ? formatCpfMask(data.customer.document_number) : '',
    email: data?.customer?.email ?? '',
    default: '',
  };

  useEffect(() => {
    const checkUserData = async () => {
      const isCustomerDataValid = await validateSchema(
        {
          ...data?.customer,
          merchant_request_amount: data?.merchant_request_amount,
          accepted_terms: Boolean(data?.customer?.terms_accepted_at),
        },
        userDataRequiredOnly,
      );

      if (isCustomerDataValid) {
        setCustomerDataValidated(true);
      }
    };
    checkUserData(checkUserData);
  }, [data]);

  return (
    <Formik
      validationSchema={
        customerDataValidated ? pixKeyWithTermsSchema : pixKeySchema
      }
      initialValues={{
        pix_key: data?.customer?.document_number ?? '',
        pix_type: 'cpf',
        ...(customerDataValidated && {
          accepted_terms: Boolean(data?.customer?.terms_accepted_at),
        }),
      }}
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={({ pix_key, pix_type }) => {
        setData((state) => ({
          ...state,
          bank_payment_data: {
            ...state.payment_data,
            pix_type,
            pix_key: formatPixKeyString(pix_key, pix_type),
            paymentDataValidated: true,
          },
        }));

        if (customerDataValidated) {
          submitData({
            ...data,
            accepted_terms: true,
            bank_payment_data: {
              ...data?.bank_payment_data,
              pix_type,
              pix_key: formatPixKeyString(pix_key, pix_type),
              paymentDataValidated: true,
            },
          });
        } else {
          history.push(`/${id}/${token}${history.location.search}`);
        }
      }}
    >
      {({ errors, setErrors, setFieldTouched, setFieldValue, values }) => (
        <FormStyled onChange={() => setErrors({})}>
          <InputWrapper>
            <Input
              id="pix_type"
              name="pix_type"
              as="select"
              label={i18n.t('form.pixType')}
              placeholder={i18n.t('form.pixTypePlaceholder')}
              value={values?.pix_type}
              error={errors?.pix_type}
              errorText={errors?.pix_type}
              handleBlur={() => setFieldTouched('pix_type')}
              handleChange={({ target: t }) => {
                setFieldTouched('pix_type', true);
                setFieldValue('pix_type', t.value);
                setData((state) => ({
                  ...state,
                  bank_payment_data: {
                    ...state.bank_payment_data,
                    pix_type: t.value,
                  },
                }));

                setFieldValue(
                  'pix_key',
                  fieldValues[t.value] || fieldValues.default,
                );
              }}
              options={pixTypeOptions}
            />

            <Input
              id="pix_key"
              name="pix_key"
              type="text"
              label={i18n.t('form.pixKey')}
              placeholder={i18n.t(`form.${values.pix_type}Placeholder`)}
              value={values?.pix_key}
              error={errors?.pix_key}
              errorText={errors?.pix_key}
              handleBlur={() => setFieldTouched('pix_key')}
              disabled={values.pix_type === 'cpf' && requestContainsCpf}
              handleChange={({ target: t }) => {
                if (values.pix_type === 'cpf') {
                  setFieldTouched('pix_key', true);
                  setFieldValue('pix_key', formatCpfMask(t.value));
                  return;
                }
                if (values.pix_type === 'phone') {
                  setFieldTouched('pix_key', true);
                  setFieldValue('pix_key', formatPhoneNumberMask(t.value));
                  return;
                }
                setFieldValue('pix_key', t.value);
              }}
            />
            {data?.merchant_request_amount && (
              <AmountSelection
                name="merchant_amount"
                errors={errors}
                label={i18n.t('form.withdrawalAmount')}
                disabled={Boolean(data?.merchant_request_amount)}
                value={data.merchant_request_amount}
                setFieldTouched={setFieldTouched}
                handleClick={setFieldValue}
                currency={data?.merchant_request_currency_iso || 'BRL'}
                handleChange={({ target: t }) => {
                  setFieldTouched('merchant_amount', true);

                  if (t.value.toString().length <= MAX_DIGITS) {
                    setFieldValue(
                      'merchant_amount',
                      t.value.replace(/[^0-9]+/g, ''),
                    );
                  }
                }}
              />
            )}
          </InputWrapper>
          {customerDataValidated && (
            <CheckboxWrapper>
              <CheckBox
                error={Boolean(errors?.accepted_terms)}
                checked={values?.accepted_terms}
                onChange={() => {
                  setFieldTouched('accepted_terms');
                  setFieldValue('accepted_terms', !values.accepted_terms);
                }}
              />
              <Paragraph>
                <Trans
                  i18nKey="form.terms"
                  i18n={i18n}
                  components={[
                    <Link
                      to={{
                        pathname: `/terms-and-conditions/${id}/${token}`,
                        search: history.location.search,
                      }}
                    />,
                    <Link
                      to={{
                        pathname: `/privacy-policy/${id}/${token}`,
                        search: history.location.search,
                      }}
                    />,
                  ]}
                />
              </Paragraph>
              {errors?.accepted_terms && (
                <ErrorText>{errors?.accepted_terms}</ErrorText>
              )}
            </CheckboxWrapper>
          )}
          <Button type="submit" disabled={!values?.pix_key}>
            {!values?.pix_key
              ? i18n.t(`form.submit.enterYourPixKey.${values.pix_type}`)
              : customerDataValidated
              ? i18n.t('form.submit.withdrawal')
              : i18n.t('form.submit.next')}
          </Button>
        </FormStyled>
      )}
    </Formik>
  );
}

PaymentDataForm.propTypes = {
  setData: PropTypes.func,
  setError: PropTypes.func,
  setIsFetching: PropTypes.func,
  data: PropTypes.shape({
    customer: PropTypes.shape({
      birth_date: PropTypes.string,
      terms_accepted_at: PropTypes.string,
      document_number: PropTypes.string,
      email: PropTypes.string,
    }),
    merchant_request_amount: PropTypes.number,
    merchant_request_currency_iso: PropTypes.string,
    type: PropTypes.string,
    bank_payment_data: PropTypes.shape({
      pix_key: PropTypes.string,
      pix_type: PropTypes.string,
      allowReview: PropTypes.bool,
    }),
  }),
};

export default PaymentDataForm;
