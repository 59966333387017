import React from 'react';
import { useHistory } from 'react-router-dom';

import { Container, Main } from '@/components/atoms/Containers/styles';
import Article from '@/components/molecules/Article';
import Footer from '@/components/molecules/Footer';
import Header from '@/components/molecules/Header';
import i18n from '@/translate/i18n';

function PrivacyPolicy() {
  const history = useHistory();

  return (
    <Container>
      <Main>
        <Header title={i18n.t('header.return')} handleClick={history.goBack} />
        <Article
          textContent={i18n.t('privacyPolicy.content', {
            returnObjects: true,
          })}
        />
        <Footer />
      </Main>
    </Container>
  );
}

export default PrivacyPolicy;
