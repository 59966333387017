import styled from 'styled-components';
import { Form } from 'formik';

export const FormStyled = styled(Form)`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-evenly;
  padding-bottom: 1rem;
  position: relative;
`;

export const InputWrapper = styled.div`
  animation: opacityZeroToOne 0.6s;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
`;
