import styled from 'styled-components';

import { ReactComponent as PixKey } from '@/assets/images/pix-key.svg';

export const Wrapper = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.info.primary};
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.font.family.bold};
  margin-bottom: 0.5rem;

  div {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  strong {
    color: ${({ theme }) => theme.colors.info.primary};
    font-size: ${({ theme }) => theme.font.size.large};
    text-transform: uppercase;
  }
`;

export const PixIcon = styled(PixKey)`
  fill: ${({ theme }) => theme.colors.info.primary};
  height: 25px;
  width: 50px;
`;
