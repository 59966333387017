import React from 'react';
import PropTypes from 'prop-types';

import Button from '@/components/atoms/Buttons/Button';
import i18n from '@/translate/i18n';
import { ButtonWrapper, SucessIcon, Wrapper } from './styles';
import { Paragraph, Title } from '@/components/atoms/Typography/styles';

function SucessMessage({
  handleClick,
  messageDescription,
  messageTitle,
  subtitle,
  title,
}) {
  return (
    <Wrapper>
      <div>
        <Title>{title}</Title>
        <Paragraph>{subtitle}</Paragraph>
      </div>
      <div>
        <SucessIcon />
        <Title>{messageTitle}</Title>
        <Paragraph>{messageDescription}</Paragraph>
      </div>
      <ButtonWrapper>
        <Paragraph>{i18n.t('confirmation.description')}</Paragraph>
        {handleClick && (
          <Button type="submit" handleClick={handleClick}>
            {i18n.t('confirmation.return')}
          </Button>
        )}
      </ButtonWrapper>
    </Wrapper>
  );
}

SucessMessage.propTypes = {
  handleClick: PropTypes.func,
  messageDescription: PropTypes.string,
  messageTitle: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

export default SucessMessage;
