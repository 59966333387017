import React from 'react';
import { Route, Switch } from 'react-router-dom';

import {
  AddressData,
  Checkout,
  DepositStatus,
  IdValidation,
  OcrValidation,
  PaymentData,
  PrivacyPolicy,
  SOFValidation,
  SOFValidationUpload,
  TermsAndConditions,
  TransactionDetails,
  TransactionStatus,
  UploadProofOfAddress,
  WithdrawalStatus,
} from './components/pages';

function Routes() {
  return (
    <Switch>
      <Route
        exact
        path="/withdrawal/status/:id?/:token?"
        component={WithdrawalStatus}
      />
      <Route
        exact
        path="/transaction-status/:id/:token"
        component={TransactionStatus}
      />
      <Route
        exact
        path="/deposit/status/:id?/:token?"
        component={DepositStatus}
      />
      <Route
        exact
        path="/privacy-policy/:id?/:token?"
        component={PrivacyPolicy}
      />
      <Route
        exact
        path="/terms-and-conditions/:id?/:token?"
        component={TermsAndConditions}
      />
      <Route
        exact
        path="/kyc/:kycLevel(2)/customer/:customerId/:token"
        component={IdValidation}
      />
      <Route
        exact
        path="/kyc/:kycLevel(2)/customer/:customerId/:token/identity"
        component={OcrValidation}
      />
      <Route
        exact
        path="/kyc/:kycLevel(3)/customer/:customerId/:token"
        component={SOFValidation}
      />
      <Route
        exact
        path="/kyc/:kycLevel(3)/customer/:customerId/:token/sof"
        component={SOFValidationUpload}
      />
      <Route
        exact
        path="/kyc/:kycLevel(3)/customer/:customerId/:token/address"
        component={UploadProofOfAddress}
      />
      <Route exact path="/address/:id/:token" component={AddressData} />
      <Route
        exact
        path="/transaction-details/:id/:token"
        component={TransactionDetails}
      />
      <Route exact path="/payment-data/:id/:token" component={PaymentData} />
      <Route exact path="/:id/:token" component={Checkout} />
    </Switch>
  );
}

export default Routes;
