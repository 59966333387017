import React from 'react';
import { useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import GlobalStyle from '@/styles/global';
import TransactionProvider from '@/context/transactionContext';
import { dark, main } from '@/styles/themes';

import Routes from './routes';

function App() {
  const { search } = useLocation();
  const theme = new URLSearchParams(search).get('theme');

  return (
    <TransactionProvider>
      <ThemeProvider theme={theme === 'dark' ? dark : main}>
        <GlobalStyle />
        <Routes />
      </ThemeProvider>
    </TransactionProvider>
  );
}

export default App;
