import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import textContent from './locales';

i18n.use(initReactI18next).init({
  debug: false,
  defaultNS: ['translations'],
  fallbackLng: 'pt-BR',
  ns: ['translations'],
  resources: textContent,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
