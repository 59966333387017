import React from 'react';
import PropTypes from 'prop-types';

import i18n from '@/translate/i18n';
import { PixIcon, Wrapper } from './styles';

function PixKeyIcon({ pixKey, type }) {
  return (
    <Wrapper>
      <div>
        <PixIcon />
        <strong>{i18n.t([`pixType.${type}`, 'pixType.default'])}</strong>
      </div>
      <span>{pixKey}</span>
    </Wrapper>
  );
}

PixKeyIcon.propTypes = {
  pixKey: PropTypes.string,
  type: PropTypes.string,
};

export default PixKeyIcon;
