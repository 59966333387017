import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as CloseIcon } from '@/assets/images/close.svg';
import { ModalContainer, ModalWrapper, CloseButton } from './styles';

function Modal({ children, height, maxWidth, removeCloseBtn, setShowModal }) {
  return (
    <ModalContainer onClick={!removeCloseBtn && (() => setShowModal(false))}>
      <ModalWrapper
        height={height}
        onClick={(e) => e.stopPropagation()}
        maxWidth={maxWidth}
      >
        {!removeCloseBtn && (
          <CloseButton type="button" onClick={() => setShowModal(false)}>
            <CloseIcon />
          </CloseButton>
        )}
        {children}
      </ModalWrapper>
    </ModalContainer>
  );
}

Modal.propTypes = {
  children: PropTypes.element.isRequired,
  height: PropTypes.string,
  maxWidth: PropTypes.string,
  removeCloseBtn: PropTypes.bool,
  setShowModal: PropTypes.func.isRequired,
};

export default Modal;
