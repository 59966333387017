import styled, { css } from 'styled-components';

export const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.bg.contrast};
  display: flex;
  font-family: ${({ theme }) => theme.font.family.primary};
  justify-content: center;
  min-height: 100vh;
  overflow-x: hidden;
`;

export const Main = styled.main`
  ${({ theme, minHeight = '650px' }) => css`
    align-items: center;
    background-color: ${theme.colors.bg.primary};
    border-radius: 0;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;
    max-height: 860px;
    max-width: 595px;
    min-height: ${minHeight};
    padding-top: 65px;
    position: relative;
    text-align: center;
    width: 100vw;

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      border-radius: 25px;
      height: 100%;
      min-width: 400px;
    }

    & > :nth-child(2) {
      flex: 1;
    }
  `}
`;

export const TextContentWrapper = styled.section`
  height: 75%;
  margin-top: 1.5rem;
  overflow-y: auto;
  padding: 0 1rem;
  text-align: left;
  width: 85%;

  h1 {
    margin-bottom: 1rem;
    text-align: center;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 75%;
  }
`;
