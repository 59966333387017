import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import AddressForm from '@/components/organisms/AddressForm';
import CircularLoader from '@/components/atoms/CircularLoader';
import ErrorMessage from '@/components/atoms/ErrorMessage';
import Footer from '@/components/molecules/Footer';
import Header from '@/components/molecules/Header';
import i18n from '@/translate/i18n';
import { Container, Main } from '@/components/atoms/Containers/styles';
import { Paragraph, Title } from '@/components/atoms/Typography/styles';
import { useTransactionData } from '@/context/transactionContext';
import {
  ContentWrapper,
  TextWrapper,
} from '@/components/atoms/FormPageStyles/styles';

function AddressData() {
  const history = useHistory();
  const { id, token } = useParams();
  const { transactionData, setTransactionData } = useTransactionData();
  const [error, setError] = useState('');
  const [isFetching, setIsFetching] = useState(false);

  if (!transactionData?.id) {
    history.push(`/${id}/${token}${history.location.search}`);
  }

  return (
    <Container>
      <Main>
        <Header
          title={!error && i18n.t('header.previous')}
          handleClick={() =>
            history.push(`/${id}/${token}${history.location.search}`)
          }
        />

        {isFetching ? (
          <CircularLoader />
        ) : error ? (
          <ErrorMessage>{error}</ErrorMessage>
        ) : (
          <ContentWrapper>
            <TextWrapper>
              <Title>{i18n.t(['checkout.addressForm.formTitle', ''])}</Title>
              <Paragraph>
                {i18n.t(['checkout.addressForm.description', ''])}
              </Paragraph>
            </TextWrapper>
            <AddressForm
              data={transactionData}
              setData={setTransactionData}
              setIsFetching={setIsFetching}
              setError={setError}
            />
          </ContentWrapper>
        )}
        <Footer />
      </Main>
    </Container>
  );
}

export default AddressData;
