import styled from 'styled-components';
import { ReactComponent as User } from '@/assets/images/userFull.svg';

export const TextWrapper = styled.div`
  justify-self: flex-start;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    max-width: 420px;
  }
`;

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 85%;
  justify-content: space-around;

  p strong {
    font: ${({ theme }) =>
      `${theme.font.size.medium} ${theme.font.family.bold}`};
  }
`;

export const UserIcon = styled(User)`
  fill: ${({ theme }) => theme.colors.divider};
  height: 120px;
  width: 120px;
`;
