import styled from 'styled-components';

export const InputWrapper = styled.div`
  align-items: center;
  color: ${({ theme, error }) =>
    error ? theme.colors.danger : theme.colors.primary};
  display: flex;
  flex-direction: row;
  font: ${({ theme }) =>
    `${theme.font.size.xxLarge} ${theme.font.family.primary}`};
  margin: 1.5rem 0 0 24%;
  text-align: center;
  transition: 0.1s margin-left linear;

  &:disabled {
    opacity: 0.6;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin: 0.5rem 0 0.3rem 35%;
  }
`;

export const Input = styled.input`
  -moz-appearance: textfield; //hide arrows in firefox
  background-color: transparent;
  border: none;
  color: ${({ theme, error }) =>
    error ? theme.colors.danger : theme.colors.primary};
  font: ${({ theme }) =>
    `${theme.font.size.xxLarge} ${theme.font.family.primary}`};
  margin-left: 5px;
  width: 130px;

  &:disabled {
    opacity: 1;
  }

  &::placeholder {
    color: ${({ color, theme }) => color || theme.colors.primary};
  }

  &::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
