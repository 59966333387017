import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

export const TransactionContext = React.createContext({});

export default function TransactionProvider({ children }) {
  const [transactionData, setTransactionData] = useState({});

  return (
    <TransactionContext.Provider
      value={{
        transactionData,
        setTransactionData,
      }}
    >
      {children}
    </TransactionContext.Provider>
  );
}

export function useTransactionData() {
  return useContext(TransactionContext);
}

TransactionProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
}.isRequired;
