import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import ErrorMessage from '@/components/atoms/ErrorMessage';
import Footer from '@/components/molecules/Footer';
import Header from '@/components/molecules/Header';
import SucessMessage from '@/components/atoms/SucessMessage';
import i18n from '@/translate/i18n';
import { Container, Main } from '@/components/atoms/Containers/styles';

function DepositStatus() {
  const location = useLocation();
  const history = useHistory();
  const { id, token } = useParams();

  if (!location?.state?.data) {
    history.replace(`/${id}/${token}${history.location.search}`);
  }
  const transactionData = location.state.data;

  return (
    <Container>
      <Main>
        <Header
          title={
            transactionData?.status === 'completed'
              ? i18n.t('confirmation.titleSucess')
              : i18n.t('confirmation.titleFail')
          }
        />
        {transactionData?.status === 'completed' ? (
          <SucessMessage
            title={i18n.t('confirmation.congrats')}
            subtitle={i18n.t('confirmation.content')}
            handleClick={
              transactionData?.redirect_url
                ? () => window.location.replace(transactionData?.redirect_url)
                : null
            }
          />
        ) : transactionData?.status === 'pending' ? (
          <SucessMessage
            title={i18n.t('confirmation.confirmedTransaction')}
            messageDescription={i18n.t('confirmation.pendingMessage')}
            handleClick={
              transactionData?.redirect_url
                ? () => window.location.replace(transactionData?.redirect_url)
                : null
            }
          />
        ) : (
          <ErrorMessage
            handleClick={() =>
              history.replace(`/${id}/${token}${history.location.search}`)
            }
          >
            {i18n.t([
              `error.transaction.${transactionData?.message}`,
              'error.transaction.unspecific',
            ])}
          </ErrorMessage>
        )}
        <Footer />
      </Main>
    </Container>
  );
}

export default DepositStatus;
