import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper } from './styles';

function CheckBox({ name, error, ...rest }) {
  return (
    <Wrapper error={error}>
      <label htmlFor={name} className="container">
        <input type="checkbox" name={name} {...rest} />
        <span className="checkmark" />
      </label>
    </Wrapper>
  );
}

CheckBox.propTypes = {
  name: PropTypes.string,
  error: PropTypes.bool,
};

export default CheckBox;
