import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Logo } from './styles';
import { Title } from '@/components/atoms/Typography/styles';

function MerchantLogo({ name, slug, width }) {
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsError(false);
  }, [slug]);

  return (
    <>
      {!isError ? (
        <Logo
          alt={name || ''}
          onError={setIsError}
          src={`/images/merchant/${slug}.svg`}
          title={name || ''}
          width={width}
        />
      ) : (
        <Title color="highlight">{name}</Title>
      )}
    </>
  );
}

MerchantLogo.propTypes = {
  name: PropTypes.string,
  slug: PropTypes.string.isRequired,
  width: PropTypes.string,
}.isRequired;

export default MerchantLogo;
