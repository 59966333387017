import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Footer from '@/components/molecules/Footer';
import Header from '@/components/molecules/Header';
import SucessMessage from '@/components/atoms/SucessMessage';
import i18n from '@/translate/i18n';
import { Container, Main } from '@/components/atoms/Containers/styles';
import { formatCurrency } from '@/utils';
import { useTransactionData } from '@/context/transactionContext';

function WithdrawalStatus() {
  const history = useHistory();
  const { id, token } = useParams();

  const { transactionData } = useTransactionData();

  if (!transactionData?.id) {
    history.push(`/${id}/${token}${history.location.search}`);
  }

  return (
    <Container>
      <Main>
        <Header title={i18n.t('confirmation.titleSucess')} />
        <SucessMessage
          title={i18n.t('confirmation.congrats')}
          subtitle={i18n.t('confirmation.content')}
          handleClick={
            transactionData?.redirect_url &&
            (() => window.location.replace(transactionData.redirect_url))
          }
          messageTitle={`${i18n.t(
            'confirmation.requiredAmount',
          )} ${formatCurrency(
            transactionData?.merchant_request_amount,
            transactionData?.merchant_request_currency_iso,
            true,
          )}`}
          messageDescription={i18n.t('confirmation.withdrawalDescription')}
        />
        <Footer />
      </Main>
    </Container>
  );
}

export default WithdrawalStatus;
