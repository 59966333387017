import { useHistory, useParams } from 'react-router-dom';

import { useTransactionData } from '@/context/transactionContext';

function useRedirect() {
  const history = useHistory();
  const { id, token } = useParams();
  const { transactionData } = useTransactionData();

  const pendingKycURL = transactionData?.customer?.pending_kyc_url;

  const checkDataAndRedirect = () => {
    const isWithdrawalPending =
      transactionData?.type === 'withdrawal' &&
      transactionData?.status === 'pending' &&
      !transactionData?.customer_approval &&
      !transactionData?.bank_payment_data?.paymentDataValidated;

    const isWithdrawalInProcess =
      transactionData?.type === 'withdrawal' &&
      transactionData?.customer_approval;

    const isDepositAlreadyRequested =
      transactionData?.type === 'deposit' &&
      transactionData?.status !== 'pending';

    if (pendingKycURL && transactionData?.type === 'deposit') {
      return history.push(pendingKycURL);
    }

    if (isWithdrawalPending) {
      return history.push(
        `/payment-data/${id}/${token}${history.location.search}`,
      );
    }

    if (transactionData?.type === 'deposit' && transactionData?.pix_code) {
      return history.push(
        `/transaction-details/${id}/${token}${history.location.search}`,
      );
    }

    if (isWithdrawalInProcess || isDepositAlreadyRequested) {
      return history.push(
        `/transaction-status/${id}/${token}${history.location.search}`,
      );
    }
    return null;
  };

  return { checkDataAndRedirect };
}

export default useRedirect;
