import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { Button, CamIcon, Text, Wrapper } from './styles';
import { HiddenInputFile } from '@/components/pages/SOFValidationUpload/styles';

function CaptureImage({ title, setFile }) {
  const inputRef = useRef(null);

  return (
    <Wrapper>
      <Button type="button" onClick={() => inputRef.current.click()}>
        <CamIcon />
        {title && <Text>{title}</Text>}
      </Button>
      <HiddenInputFile
        type="file"
        ref={inputRef}
        accept="image/*"
        capture="environment"
        onChange={(e) => {
          setFile(e.target.files[0]);
        }}
      />
    </Wrapper>
  );
}

CaptureImage.propTypes = {
  title: PropTypes.string,
  setFile: PropTypes.func,
}.isRequired;

export default CaptureImage;
