import styled from 'styled-components';

import { Link as RouterLink } from 'react-router-dom';
import { ReactComponent as Upload } from '@/assets/images/upload.svg';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const UploadIcon = styled(Upload)`
  fill: ${({ theme }) => theme.colors.divider};
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.info.secondary};
  font-size: ${({ theme }) => theme.font.size.small};
  margin-top: 2rem;
  text-transform: uppercase;
`;

export const ImagePreview = styled.img`
  max-height: 300px;
  max-width: 150px;
`;

export const Button = styled.button`
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.info.primary};
  margin-top: 2rem;
  text-decoration: underline;
`;

export const Link = styled(RouterLink)`
  border: 2px dashed ${({ theme }) => theme.colors.divider};
  color: ${({ theme }) => theme.colors.info.primary};
  margin-bottom: 1rem;
  padding: 1rem 2rem;
`;
