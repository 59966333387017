import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Trans } from 'react-i18next';

import Button from '@/components/atoms/Buttons/Button';
import CheckBox from '@/components/atoms/Checkbox';
import CircularLoader from '@/components/atoms/CircularLoader';
import ErrorMessage from '@/components/atoms/ErrorMessage';
import { Paragraph, Title } from '@/components/atoms/Typography/styles';
import i18n from '@/translate/i18n';
import { submitAcceptTerms } from '@/services/api';
import { useTransactionData } from '@/context/transactionContext';

import { CheckboxWrapper, ContentWrapper } from './styles';

function AcceptanceOfTerms({ closeModal }) {
  const history = useHistory();
  const { id, token } = useParams();
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { setTransactionData } = useTransactionData();

  const handleAcceptTerms = async () => {
    try {
      setIsLoading(true);
      await submitAcceptTerms(id, token);

      setTransactionData((state) => ({
        ...state,
        customer: {
          ...state?.customer,
          terms_accepted_at: true,
        },
      }));
      closeModal();
    } catch (err) {
      const message = err?.response?.data?.message;
      setError(i18n.t([`error.${message}`, 'error.unspecific']));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ContentWrapper>
      {isLoading ? (
        <CircularLoader />
      ) : error ? (
        <ErrorMessage>{error}</ErrorMessage>
      ) : (
        <>
          <Title>{i18n.t('acceptanceOfTerms.title')}</Title>
          <CheckboxWrapper>
            <CheckBox
              onChange={() => {
                setAcceptedTerms((state) => !state);
              }}
            />

            <Paragraph>
              <Trans
                i18nKey="form.terms"
                i18n={i18n}
                components={[
                  <Link
                    to={{
                      pathname: `/terms-and-conditions/${id}/${token}`,
                      search: history.location.search,
                    }}
                  />,
                  <Link
                    to={{
                      pathname: `/privacy-policy/${id}/${token}`,
                      search: history.location.search,
                    }}
                  />,
                ]}
              />
            </Paragraph>
          </CheckboxWrapper>
          <Button
            type="button"
            disabled={!acceptedTerms}
            handleClick={handleAcceptTerms}
          >
            {i18n.t('acceptanceOfTerms.agreeButton')}
          </Button>
        </>
      )}
    </ContentWrapper>
  );
}

AcceptanceOfTerms.propTypes = {
  closeModal: PropTypes.func,
};

export default AcceptanceOfTerms;
