import styled from 'styled-components';

import { ReactComponent as Vpag } from '@/assets/images/vpag-logo.svg';

export const Wrapper = styled.footer`
  background-color: ${({ theme }) => theme.colors.footer.bg};
  height: 70px;
  max-width: 100%;
  padding: 0.6rem;
  position: relative;
  width: 100vw;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    & {
      border-radius: 0 0 20px 20px;
      padding: 2vh;
    }
  }
`;

export const Content = styled.section`
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-width: 100%;
`;

export const Logo = styled(Vpag)`
  path {
    fill: ${({ theme }) => theme.colors.footer.text};
  }
`;

export const LinksWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;

  a {
    color: ${({ theme }) => theme.colors.footer.text};
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    & {
      flex-direction: row;
    }
  }
`;
