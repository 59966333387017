import React from 'react';
import PropTypes from 'prop-types';

import { Paragraph, Title } from '@/components/atoms/Typography/styles';

import { TextContentWrapper, TextWrapper } from './styles';

function Article({ textContent, textIndent, title, titlePosition }) {
  return (
    <TextContentWrapper titlePosition={titlePosition}>
      {title && <Title>{title}</Title>}
      {textContent?.map((el) => (
        <div key={el.title}>
          <Title>{el.title}</Title>
          {el.paragraphs.map((item) => (
            <TextWrapper key={item?.paragraph} textIndent={textIndent}>
              {item?.renderAsHtml ? (
                <Paragraph
                  dangerouslySetInnerHTML={{
                    __html: item.paragraph,
                  }}
                  indent={item?.indent}
                />
              ) : (
                <Paragraph indent={item?.indent}>{item?.paragraph}</Paragraph>
              )}
            </TextWrapper>
          ))}
        </div>
      ))}
    </TextContentWrapper>
  );
}

Article.propTypes = {
  textContent: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      paragraphs: PropTypes.arrayOf(
        PropTypes.shape({
          paragraph: PropTypes.string.isRequired,
          renderAsHtml: PropTypes.bool,
        }),
      ),
    }),
  ),
  textIndent: PropTypes.string,
  title: PropTypes.string,
  titlePosition: PropTypes.string,
};

export default Article;
