import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Button from '@/components/atoms/Buttons/Button';
import Footer from '@/components/molecules/Footer';
import Header from '@/components/molecules/Header';
import { Container, Main } from '@/components/atoms/Containers/styles';
import { Paragraph, Title } from '@/components/atoms/Typography/styles';
import { useTransactionData } from '@/context/transactionContext';
import i18n from '@/translate/i18n';

import { ClockIcon, ContentWrapper, ErrorIcon } from './styles';

function TransactionStatus() {
  const history = useHistory();
  const { id, token } = useParams();

  const { transactionData } = useTransactionData();

  if (!transactionData?.id) {
    history.push(`/${id}/${token}${history.location.search}`);
  }

  const isCancelledTransaction = transactionData?.status === 'cancelled';

  return (
    <Container>
      <Main>
        <Header
          title={i18n.t('header.return')}
          handleClick={() =>
            history.replace(`/${id}/${token}${history.location.search}`)
          }
        />
        <ContentWrapper>
          {isCancelledTransaction ? (
            <Title>{i18n.t('transactionStatus.titleCancelled')}</Title>
          ) : (
            <div>
              <Title>{i18n.t('transactionStatus.title')}</Title>
              <Paragraph>{i18n.t('transactionStatus.description')}</Paragraph>
            </div>
          )}
          <div>
            {isCancelledTransaction ? <ErrorIcon /> : <ClockIcon />}
            <Title>{`${i18n.t(
              `transactionStatus.${transactionData?.type}.title`,
            )} ${i18n.t([
              `transactionStatus.${transactionData?.type}.status.${transactionData?.status}`,
              '',
            ])}`}</Title>
          </div>
          <div>
            <Paragraph>{i18n.t('transactionStatus.closeModal')}</Paragraph>
            {transactionData?.redirect_url && (
              <Button
                type="submit"
                handleClick={() =>
                  window.location.replace(transactionData.redirect_url)
                }
              >
                {i18n.t('confirmation.return')}
              </Button>
            )}
          </div>
        </ContentWrapper>
        <Footer />
      </Main>
    </Container>
  );
}

export default TransactionStatus;
