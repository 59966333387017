import styled from 'styled-components';

import { ReactComponent as CopyIcon } from '@/assets/images/copy.svg';

export const Button = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.header.bg};
  border: none;
  color: ${({ theme }) => theme.colors.info.highlight};
  display: flex;
  font: ${({ theme }) => `${theme.font.size.small} ${theme.font.family.bold}`};
  height: 31px;
  padding: 0.3rem 0.6rem 0.1rem;
  transition: transform 0.1s;

  &:active {
    transform: translate(1px, 1px);
  }

  &:hover {
    filter: brightness(0.6);
  }
`;

export const Icon = styled(CopyIcon)`
  margin-right: 0.3rem;

  path {
    fill: ${({ theme }) => theme.colors.info.highlight};
  }
`;
