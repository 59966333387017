import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

import Button from '@/components/atoms/Buttons/Button';
import Input from '@/components/atoms/Input';
import Select from '@/components/atoms/Select';
import i18n from '@/translate/i18n';
import useSubmitData from '@/hooks/useSubmitData';
import { FormStyled, InputWrapper } from '@/components/atoms/FormStyles/styles';
import { addressSchema } from '@/utils/validations';
import { BRAZILIAN_STATES_FULL } from '@/utils/constants';
import { formatCep } from '@/utils';

function AddressForm({ data, setIsFetching, setError, setData }) {
  const { submitData } = useSubmitData(setError, setIsFetching, setData);

  return (
    <Formik
      validationSchema={addressSchema}
      initialValues={{
        address: data?.customer?.address || '',
        postal_code: data?.customer?.postal_code
          ? formatCep(data?.customer?.postal_code)
          : '',
        state: data?.customer?.state || '',
        city: data?.customer?.city || '',
      }}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values) => {
        const { address, postal_code, state, city } = values;

        const addressData = {
          address,
          postal_code,
          state,
          city,
        };

        await submitData({
          ...data,
          customer: { ...data.customer, ...addressData },
        });
      }}
    >
      {({
        errors,
        setErrors,
        setFieldTouched,
        setFieldValue,
        submitForm,
        values,
      }) => (
        <FormStyled onChange={() => setErrors({})}>
          <InputWrapper>
            <Input
              id="address"
              name="address"
              type="text"
              label={i18n.t('addressForm.address')}
              placeholder={i18n.t('addressForm.addressPlaceholder')}
              value={values?.address}
              error={errors?.address}
              errorText={errors?.address}
              handleBlur={() => setFieldTouched('address')}
              disabled={Boolean(data?.customer?.address)}
            />
            <Input
              id="postalCode"
              name="postalCode"
              type="text"
              label={i18n.t('addressForm.postalCode')}
              placeholder={i18n.t('addressForm.postalCodePlaceholder')}
              value={values?.postal_code}
              error={errors?.postal_code}
              errorText={errors?.postal_code}
              handleBlur={() => setFieldTouched('postal_code')}
              handleChange={({ target: t }) => {
                setFieldTouched('postal_code', true);
                setFieldValue('postal_code', formatCep(t.value));
              }}
              disabled={Boolean(data?.customer?.postal_code)}
            />
            <Select
              id="state"
              name="state"
              type="text"
              label={i18n.t('addressForm.state')}
              placeholder={i18n.t('addressForm.statePlaceholder')}
              value={values?.state}
              error={errors?.state}
              errorText={errors?.state}
              handleBlur={() => setFieldTouched('state')}
              handleChange={({ target: t }) => {
                setFieldTouched('state', true);
                setFieldValue('state', t.value);
              }}
              disabled={Boolean(data?.customer?.state)}
              options={BRAZILIAN_STATES_FULL}
            />
            <Input
              id="city"
              name="city"
              type="text"
              label={i18n.t('addressForm.city')}
              placeholder={i18n.t('addressForm.cityPlaceholder')}
              value={values?.city}
              error={errors?.city}
              errorText={errors?.city}
              handleBlur={() => setFieldTouched('city')}
              disabled={Boolean(data?.customer?.city)}
            />
          </InputWrapper>
          <Button
            type="button"
            handleClick={() => {
              submitForm(true);
            }}
          >
            {i18n.t([`form.submit.${data?.type}`, 'form.submit.default'])}
          </Button>
        </FormStyled>
      )}
    </Formik>
  );
}

AddressForm.propTypes = {
  setData: PropTypes.func,
  setError: PropTypes.func,
  setIsFetching: PropTypes.func,
  data: PropTypes.shape({
    customer: PropTypes.shape({
      address: PropTypes.string,
      city: PropTypes.string,
      postal_code: PropTypes.number,
      state: PropTypes.string,
    }),
    type: PropTypes.string,
  }),
};

export default AddressForm;
