import portugueseContent from './pt.json';
import spanishContent from './es.json';
import englishContent from './en.json';

const textContent = {
  ...portugueseContent,
  ...spanishContent,
  ...englishContent,
};

export default textContent;
