import styled from 'styled-components';

import { ReactComponent as SucessImage } from '@/assets/images/sucess.svg';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 75%;
  justify-content: center;
`;

export const SucessIcon = styled(SucessImage)`
  fill: ${({ theme }) => theme.colors.divider};
  height: 100px;
  margin-bottom: 1rem;
  width: 100px;
`;
