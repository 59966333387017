export const formatCpfMask = (value) =>
  value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');

export const formatCep = (cep) =>
  cep
    .replace(/\D/g, '')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{3})\d+?$/, '$1');

export const formatCpfOnlyNumbers = (value) =>
  value.replaceAll('.', '').replaceAll('-', '');

export const formatDateMask = (date) =>
  date
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{4})\d+?$/, '$1');

export const formatPhoneNumberMask = (phoneNumber) =>
  phoneNumber.replace(/\D/g, '').replace(/(\d{11})(\d)/, '$1');

export const formatDateWithSlash = (date) => {
  if (!date) {
    return null;
  }
  const d = date.split('-');

  return `${d[2]}/${d[1]}/${d[0]}`;
};

export const formatDateWithDashs = (date) => {
  const d = date.split('/');

  return `${d[2]}-${d[1]}-${d[0]}`;
};

export const validateMinimumAge = (dateString) => {
  const MINIMUM_AGE = 18;
  const DATE_LENGTH = 10;

  if (dateString && dateString.length === DATE_LENGTH) {
    const today = new Date();
    const dayOfBirth = dateString.slice(0, 2);
    const monthOfBirth = dateString.slice(3, 5);
    const yearOfBirth = dateString.slice(6, 10);

    const birthDateTimeStamp = new Date(
      yearOfBirth,
      monthOfBirth - 1,
      dayOfBirth - 1,
    );

    const diff = today - birthDateTimeStamp;
    const age = Math.floor(diff / 31557600000); // Divide by 1000*60*60*24*365.25

    return age >= MINIMUM_AGE;
  }

  return false;
};

export const formatCurrency = (value, selectedCurrency, showDecimals) => {
  const result = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: selectedCurrency || 'BRL',
    maximumFractionDigits: showDecimals ? 2 : 0,
  }).format(value);

  // replace non-breaking space (&nbsp;) with a regular space
  return result.replace(/\u00a0/g, ' ');
};

const padTo2Digits = (num) => num.toString().padStart(2, '0');

export const convertMsToTime = (milliseconds) => {
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds %= 60;
  minutes %= 60;
  hours %= 24;

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
    seconds,
  )}`;
};

export const getCurrencySymbol = (locale, currency) =>
  (0)
    .toLocaleString(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();

export const convertToBase64 = (file) =>
  new Promise((resolve) => {
    let baseURL = '';
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      baseURL = reader.result;
      resolve(baseURL);
    };
    return baseURL;
  });

export const formatPixKeyString = (pixKey, pixType) => {
  switch (pixType) {
    case 'cpf':
      return formatCpfOnlyNumbers(pixKey);
    case 'phone':
      return `+55${pixKey?.replaceAll(' ', '')}`;
    default:
      return pixKey;
  }
};

export const fallbackCopyTextToClipboard = (text, onSuccess) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
    onSuccess();
  } catch (err) {
    console.log(err.message);
  }

  document.body.removeChild(textArea);
};

export const copyTextToClipboard = (text, onSuccess) => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text, onSuccess);
    return;
  }
  navigator.clipboard.writeText(text).then(
    () => {
      onSuccess();
    },
    (err) => {
      fallbackCopyTextToClipboard(text, onSuccess);
    },
  );
};
