import styled from 'styled-components';

export const TextContentWrapper = styled.article`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  margin-top: 0.3rem;
  max-height: 550px;
  overflow-y: auto;
  padding: 2rem 1rem 0;
  width: 85%;

  h1 {
    margin-bottom: 1rem;
    text-align: ${({ titlePosition }) => titlePosition ?? 'center'};
  }

  & > h1 {
    text-align: center;
    text-transform: uppercase;
  }
`;

export const TextWrapper = styled.div`
  padding: 0.5rem 0;

  li {
    list-style-position: inside;
    margin-bottom: 0.5rem;
    padding-left: 2em;
    text-indent: -1.3em;
  }

  p {
    text-align: left;
    text-indent: ${({ textIndent }) => textIndent ?? '1.5rem'};
  }
`;
