import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Trans } from 'react-i18next';

import Button from '@/components/atoms/Buttons/Button';
import CheckBox from '@/components/atoms/Checkbox';
import { FormStyled, InputWrapper } from '@/components/atoms/FormStyles/styles';
import AmountSelection from '@/components/molecules/AmountSelection';
import Input from '@/components/atoms/Input';
import { Paragraph } from '@/components/atoms/Typography/styles';
import useSubmitData from '@/hooks/useSubmitData';
import { setCustomerDocument } from '@/services/api';
import i18n from '@/translate/i18n';
import { cpfSchema, userDataSchema } from '@/utils/validations';
import { formatCpfMask, formatCpfOnlyNumbers } from '@/utils';

import { CPFWrapper, CheckboxWrapper, ErrorText } from './styles';

const MAX_DIGITS = 6;

function UserDataForm({ data, setData, setError, setIsFetching }) {
  const history = useHistory();
  const { id, token } = useParams();
  const { submitData } = useSubmitData(setError, setIsFetching, setData);

  const isDocumentNumberMissingInDeposit =
    Boolean(!data?.customer?.document_number) && data?.type === 'deposit';

  const requestTransaction = async (values) => {
    const { accepted_terms, document_number, email, merchant_request_amount } =
      values;

    const transactionData = {
      customer: {
        document_number: formatCpfOnlyNumbers(document_number),
        email,
      },
      merchant_request_amount,
    };

    setData((state) => ({
      ...state,
      merchant_request_amount,
      accepted_terms,
      customer_data: {
        ...state.customer,
        ...transactionData.customer,
      },
    }));

    submitData({
      ...data,
      merchant_request_amount,
      accepted_terms,
      customer: {
        ...data.customer,
        ...transactionData.customer,
      },
    });
  };

  const requestUserData = async ({ document_number }) => {
    try {
      setIsFetching(true);
      const res = await setCustomerDocument(id, token, {
        document_number: formatCpfOnlyNumbers(document_number),
      });

      setData((state) => ({
        ...state,
        ...res.data,
      }));
    } catch (err) {
      setData((state) => ({
        ...state,
        customer: {
          ...state?.customer,
          document_number,
        },
      }));
    } finally {
      setIsFetching(false);
    }
  };
  const userCpf =
    data?.type === 'withdrawal' && data?.bank_payment_data?.pix_type === 'cpf'
      ? data?.bank_payment_data?.pix_key
      : null;

  return (
    <Formik
      validationSchema={
        isDocumentNumberMissingInDeposit ? cpfSchema : userDataSchema
      }
      initialValues={{
        accepted_terms: Boolean(data?.customer?.terms_accepted_at),
        merchant_request_amount: data?.merchant_request_amount || '',
        document_number: formatCpfMask(
          data?.customer?.document_number ||
            data?.customer_data?.document_number ||
            userCpf ||
            '',
        ),
        email: data?.customer?.email || data?.customer_data?.email || '',
      }}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={
        isDocumentNumberMissingInDeposit ? requestUserData : requestTransaction
      }
    >
      {({ errors, setErrors, setFieldTouched, setFieldValue, values }) => (
        <FormStyled onChange={() => setErrors({})}>
          <InputWrapper>
            {data?.customer?.document_number && data?.type === 'deposit' ? (
              <CPFWrapper>
                CPF {formatCpfMask(data?.customer.document_number)}
              </CPFWrapper>
            ) : (
              <Input
                id="cpf"
                name="cpf"
                type="text"
                label={i18n.t('form.cpf')}
                placeholder={i18n.t('form.cpfPlaceholder')}
                value={values?.document_number || userCpf}
                error={errors?.document_number}
                errorText={errors?.document_number}
                handleBlur={() => setFieldTouched('document_number')}
                handleChange={({ target }) => {
                  setFieldTouched('document_number', true);
                  setFieldValue('document_number', formatCpfMask(target.value));
                }}
                disabled={!!data?.customer?.document_number || userCpf}
              />
            )}
            {!isDocumentNumberMissingInDeposit && (
              <>
                <Input
                  id="email"
                  name="email"
                  type="text"
                  label={i18n.t('form.email')}
                  placeholder={i18n.t('form.emailPlaceholder')}
                  value={values?.email}
                  error={errors?.email}
                  errorText={errors?.email}
                  handleBlur={() => setFieldTouched('email')}
                  disabled={Boolean(data?.customer?.email)}
                />
                <AmountSelection
                  name="merchant_request_amount"
                  errors={errors}
                  disabled={Boolean(data?.merchant_request_amount)}
                  value={values?.merchant_request_amount}
                  label={
                    Boolean(data?.merchant_request_amount) &&
                    i18n.t([
                      `form.amountLabel.${data?.type}`,
                      'form.amountLabel.default',
                    ])
                  }
                  setFieldTouched={setFieldTouched}
                  handleClick={setFieldValue}
                  currency={data?.merchant_request_currency_iso || 'BRL'}
                  handleChange={({ target }) => {
                    setFieldTouched('merchant_request_amount', true);

                    if (target.value.toString().length <= MAX_DIGITS) {
                      setFieldValue(
                        'merchant_request_amount',
                        target.value.replace(/[^0-9]+/g, ''),
                      );
                    }
                  }}
                />
              </>
            )}
          </InputWrapper>
          {!isDocumentNumberMissingInDeposit && (
            <CheckboxWrapper>
              <CheckBox
                error={Boolean(errors?.accepted_terms)}
                checked={values?.accepted_terms}
                onChange={() => {
                  setFieldTouched('accepted_terms');
                  setFieldValue('accepted_terms', !values.accepted_terms);
                }}
              />
              <Paragraph>
                <Trans
                  i18nKey="form.terms"
                  i18n={i18n}
                  components={[
                    <Link
                      to={{
                        pathname: `/terms-and-conditions/${id}/${token}`,
                        search: history.location.search,
                      }}
                    />,
                    <Link
                      to={{
                        pathname: `/privacy-policy/${id}/${token}`,
                        search: history.location.search,
                      }}
                    />,
                  ]}
                />
              </Paragraph>
              {errors?.accepted_terms && (
                <ErrorText>{errors?.accepted_terms}</ErrorText>
              )}
            </CheckboxWrapper>
          )}
          <Button type="submit">
            {!isDocumentNumberMissingInDeposit
              ? i18n.t([`form.submit.${data?.type}`, 'form.submit.default'])
              : i18n.t('form.submit.next')}
          </Button>
        </FormStyled>
      )}
    </Formik>
  );
}

UserDataForm.propTypes = {
  setData: PropTypes.func,
  setError: PropTypes.func,
  setIsFetching: PropTypes.func,
  data: PropTypes.shape({
    amount: PropTypes.number,
    merchant_request_amount: PropTypes.number,
    merchant_request_currency_iso: PropTypes.string,
    pix_code: PropTypes.string,
    type: PropTypes.string,
    customer: PropTypes.shape({
      birth_date: PropTypes.string,
      document_number: PropTypes.string,
      email: PropTypes.string,
      name: PropTypes.string,
      pix_code: PropTypes.string,
      terms_accepted_at: PropTypes.bool,
    }),
    customer_data: PropTypes.shape({
      birth_date: PropTypes.string,
      document_number: PropTypes.string,
      email: PropTypes.string,
      name: PropTypes.string,
      pix_code: PropTypes.string,
    }),
    bank_payment_data: PropTypes.shape({
      pix_key: PropTypes.string,
      pix_type: PropTypes.string,
      paymentDataValidated: PropTypes.bool,
    }),
  }),
};

export default UserDataForm;
