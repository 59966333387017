import styled from 'styled-components';

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
`;

export const TextWrapper = styled.div`
  margin: 1vh 0 0.5vh;
`;
