import styled from 'styled-components';

export const ContentWrapper = styled.section`
  align-items: center;
  color: ${({ theme }) => theme.colors.info.primary};
  display: flex;
  flex-direction: column;
  height: 80%;
  justify-content: space-around;
  margin: auto 0;
`;

export const HiddenInputFile = styled.input`
  display: none;
`;
