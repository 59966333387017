import React from 'react';
import PropTypes from 'prop-types';

import InputAmount from '@/components/atoms/InputAmont';
import i18n from '@/translate/i18n';
import { Title, Paragraph } from '@/components/atoms/Typography/styles';

import { AmountWrapper, BoxButton, Label } from './styles';

const defaultOtions = [50, 100, 200, 500];

function AmountSelection({
  currency,
  disabled,
  errors,
  handleChange,
  handleClick,
  label,
  name,
  setFieldTouched,
  value,
}) {
  return (
    <div>
      {!disabled && (
        <>
          <Title>{i18n.t('form.enterAmount')}</Title>
          <Paragraph>
            {i18n.t('form.currency')} {currency}
          </Paragraph>
          <div>
            {defaultOtions.map((el) => (
              <BoxButton
                key={el}
                type="button"
                selected={el === value}
                onClick={() => handleClick(name, el)}
              >
                {el}
              </BoxButton>
            ))}
          </div>
        </>
      )}
      <AmountWrapper>
        {label && <Label>{label}</Label>}
        <InputAmount
          id={name}
          name={name}
          value={value}
          disabled={disabled}
          currency={currency}
          handleChange={handleChange}
          handleBlur={() => setFieldTouched('name')}
          error={errors?.merchant_amount}
          errorText={errors[name]}
        />
      </AmountWrapper>
    </div>
  );
}

AmountSelection.propTypes = {
  currency: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.shape({
    merchant_amount: PropTypes.string,
  }),
  handleChange: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  setFieldTouched: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default AmountSelection;
