import styled, { css } from 'styled-components';

export const ModalContainer = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 3;
`;

export const ModalWrapper = styled.div`
  ${({ height, maxWidth = '316px', theme }) => css`
    align-items: center;
    background-color: ${theme.colors.bg.primary};
    border-radius: 10px;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    color: ${theme.colors.primary};
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    max-width: ${maxWidth};
    min-height: ${height || '390px'};
    position: absolute;
    width: 90vw;
    z-index: 9;
  `}
`;

export const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 9;

  &:hover {
    background-color: ${({ theme }) => theme.colors.bg.secondary};
  }
`;
