import styled from 'styled-components';

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 300px;
  padding: 0 2rem;
  text-align: center;
`;

export const CheckboxWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: start;
  max-width: 280px;
  position: relative;
  text-align: left;

  a {
    color: ${({ theme }) => theme.colors.info.primary};
  }

  p {
    margin-left: 2rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-left: 2rem;
  }
`;
