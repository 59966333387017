import styled from 'styled-components';

export const BoxButton = styled.button`
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.primary : theme.colors.bg.secondary};
  border: none;
  color: ${({ theme, selected }) =>
    selected ? theme.colors.bg.primary : theme.colors.info.secondary};
  font: ${({ theme, selected }) =>
    `${theme.font.size.medium} ${
      selected ? theme.font.family.primary : theme.font.family.light
    }`};
  height: 38px;
  max-width: 82px;
  min-width: 51px;
  position: relative;
  transition: background-color 0.2s;
  width: 15vw;

  &:not(:last-child):before {
    border-right: 1px solid ${({ theme }) => theme.colors.divider};
    content: '';
    height: ${({ selected }) => (selected ? 0 : '50%')};
    position: absolute;
    right: 0;
    top: auto;
    width: 50%;
  }
`;

export const AmountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.info.primary};
  font-family: ${({ theme }) => theme.font.family.bold};
`;
