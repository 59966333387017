import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Button from '@/components/atoms/Buttons/Button';
import Footer from '@/components/molecules/Footer';
import Header from '@/components/molecules/Header';
import i18n from '@/translate/i18n';
import { Container, Main } from '@/components/atoms/Containers/styles';
import { ContentWrapper, TextWrapper, CoinIcon } from './styles';
import { Paragraph, Title } from '@/components/atoms/Typography/styles';

function SOFValidation() {
  const history = useHistory();
  const { customerId, token } = useParams();

  return (
    <Container>
      <Main>
        <Header title={i18n.t('header.return')} />
        <ContentWrapper>
          <TextWrapper>
            <Title>{i18n.t('sofValidation.title')}</Title>
            <Paragraph>{i18n.t('sofValidation.description')}</Paragraph>
          </TextWrapper>
          <CoinIcon />
          <Title>{i18n.t('sofValidation.sendIncomeOfPoof')}</Title>
          <Paragraph>{i18n.t('sofValidation.emailSent')}</Paragraph>
          <Button
            type="button"
            handleClick={() =>
              history.push(
                `/kyc/3/customer/${customerId}/${token}/sof${history.location.search}`,
              )
            }
          >
            {i18n.t('sofValidation.buttonLabel')}
          </Button>
        </ContentWrapper>
        <Footer />
      </Main>
    </Container>
  );
}

export default SOFValidation;
