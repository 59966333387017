import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import CircularLoader from '@/components/atoms/CircularLoader';
import ErrorMessage from '@/components/atoms/ErrorMessage';
import Footer from '@/components/molecules/Footer';
import Header from '@/components/molecules/Header';
import PaymentDataForm from '@/components/organisms/PaymentDataForm';
import PixKeyIcon from '@/components/atoms/PixKeyIcon';
import i18n from '@/translate/i18n';
import { Container, Main } from '@/components/atoms/Containers/styles';
import { Paragraph, Title } from '@/components/atoms/Typography/styles';
import { useTransactionData } from '@/context/transactionContext';
import {
  ContentWrapper,
  TextWrapper,
} from '@/components/atoms/FormPageStyles/styles';

function PaymentData() {
  const history = useHistory();
  const { id, token } = useParams();
  const { transactionData, setTransactionData } = useTransactionData();
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState('');

  if (!transactionData?.id) {
    history.push(`/${id}/${token}${history.location.search}`);
  }

  return (
    <Container>
      <Main>
        <Header title={i18n.t('header.previous')} />
        {isFetching ? (
          <CircularLoader />
        ) : error ? (
          <ErrorMessage>{error}</ErrorMessage>
        ) : (
          <ContentWrapper>
            <TextWrapper>
              <Title>{i18n.t(['checkout.withdrawal.formTitle', ''])}</Title>
              <PixKeyIcon
                type={transactionData?.bank_payment_data?.pix_type}
                pixKey={transactionData?.bank_payment_data?.pix_key}
              />

              <Paragraph>{i18n.t([`checkout.selectPixKey`, ''])}</Paragraph>
            </TextWrapper>
            <PaymentDataForm
              data={transactionData}
              setData={setTransactionData}
              setError={setError}
              setIsFetching={setIsFetching}
            />
          </ContentWrapper>
        )}
        <Footer />
      </Main>
    </Container>
  );
}

export default PaymentData;
