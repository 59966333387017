import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import CircularLoader from '@/components/atoms/CircularLoader';
import ErrorMessage from '@/components/atoms/ErrorMessage';
import Footer from '@/components/molecules/Footer';
import Header from '@/components/molecules/Header';
import PixKeyIcon from '@/components/atoms/PixKeyIcon';
import UserDataForm from '@/components/organisms/UserDataForm';
import i18n from '@/translate/i18n';
import useRedirect from '@/hooks/useRedirect';
import { Container, Main } from '@/components/atoms/Containers/styles';
import { Paragraph, Title } from '@/components/atoms/Typography/styles';
import { getTransactionData } from '@/services/api';
import { useTransactionData } from '@/context/transactionContext';
import {
  ContentWrapper,
  TextWrapper,
} from '@/components/atoms/FormPageStyles/styles';

function Checkout() {
  const history = useHistory();
  const { id, token } = useParams();
  const [error, setError] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const { transactionData, setTransactionData } = useTransactionData();
  const { checkDataAndRedirect } = useRedirect();

  useEffect(() => {
    const getTransaction = async () => {
      try {
        setIsFetching(true);
        const res = await getTransactionData(id, token);

        setTransactionData(res.data);
      } catch (err) {
        const message = err?.response?.data?.message;
        setError(i18n.t([`error.${message}`, 'error.unspecific']));
      } finally {
        setIsFetching(false);
      }
    };

    if (!Object.keys(transactionData)?.length) {
      getTransaction();
    }
  }, [history, id, setTransactionData, token, transactionData]);

  checkDataAndRedirect();

  return (
    <Container>
      <Main>
        <Header
          title={!error && i18n.t('header.previous')}
          handleClick={
            transactionData?.type === 'withdrawal' &&
            (() => {
              history.push(
                `/payment-data/${id}/${token}${history.location.search}`,
              );
            })
          }
        />

        {isFetching ? (
          <CircularLoader />
        ) : error ? (
          <ErrorMessage>{error}</ErrorMessage>
        ) : (
          <ContentWrapper>
            <TextWrapper>
              <Title>
                {i18n.t([`checkout.${transactionData?.type}.formTitle`, ''])}
              </Title>
              {transactionData?.type === 'withdrawal' && (
                <PixKeyIcon
                  type={transactionData?.bank_payment_data?.pix_type}
                  pixKey={transactionData?.bank_payment_data?.pix_key}
                />
              )}
              <Paragraph>
                {i18n.t([`checkout.${transactionData?.type}.description`, ''])}
              </Paragraph>
            </TextWrapper>

            <UserDataForm
              data={transactionData}
              setData={setTransactionData}
              setError={setError}
              setIsFetching={setIsFetching}
            />
          </ContentWrapper>
        )}
        <Footer />
      </Main>
    </Container>
  );
}

export default Checkout;
