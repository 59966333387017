import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import CircularLoader from '@/components/atoms/CircularLoader';
import { Container, Main } from '@/components/atoms/Containers/styles';
import ErrorMessage from '@/components/atoms/ErrorMessage';
import SuccessMessageDefault from '@/components/atoms/SuccessMessageDefault';
import Header from '@/components/molecules/Header';
import IdwallSdk from '@/components/organisms/IdwallSdk';
import IFrameOcr from '@/components/organisms/IFrameOcr';
import { getOcrProvider } from '@/services/api';
import i18n from '@/translate/i18n';

const IDWALL_OCR_PROVIDER_SLUG = 'idwall';

function OcrValidation() {
  const { kycLevel, customerId, token: apiToken } = useParams();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [error, setError] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [ocrProviderSlug, setOcrProviderSlug] = useState('');

  useEffect(() => {
    const getProvider = async () => {
      try {
        setIsFetching(true);
        const params = {
          customer_id: customerId,
          token: apiToken,
        };
        const res = await getOcrProvider(params);
        setOcrProviderSlug(res.data.slug);
      } catch (err) {
        const message = err?.response?.data?.message;
        setError(i18n.t([`error.${message}`, 'error.unspecific']));
      } finally {
        setIsFetching(false);
      }
    };

    getProvider();
  }, [apiToken, customerId, kycLevel]);

  return (
    <Container>
      <Main>
        <Header defaultLogo />
        {isFetching ? (
          <CircularLoader />
        ) : error ? (
          <ErrorMessage>{error}</ErrorMessage>
        ) : showSuccessMessage ? (
          <SuccessMessageDefault
            message={i18n.t('idValidation.successMessage')}
            messageDescription={i18n.t('idValidation.successDescription')}
          />
        ) : ocrProviderSlug === IDWALL_OCR_PROVIDER_SLUG ? (
          <IdwallSdk
            setShowSuccessMessage={setShowSuccessMessage}
            setError={setError}
          />
        ) : (
          <IFrameOcr setError={setError} ocrProviderSlug={ocrProviderSlug} />
        )}
      </Main>
    </Container>
  );
}

export default OcrValidation;
