import styled from 'styled-components';

export const CheckboxWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: start;
  margin-left: 2rem;
  max-width: 280px;
  padding-bottom: 2rem;
  position: relative;
  text-align: left;

  a {
    color: ${({ theme }) => theme.colors.info.primary};
  }

  p {
    margin-left: 2rem;
  }
`;

export const ErrorText = styled.div`
  bottom: 5px;
  color: ${({ theme }) => theme.colors.danger};
  font-size: ${({ theme }) => theme.font.size.xSmall};
  left: 1rem;
  position: absolute;
`;

export const CPFWrapper = styled.span`
  color: ${({ theme }) => theme.colors.info.primary};
  font: ${({ theme }) => `${theme.font.size.large} ${theme.font.family.bold}`};
  margin-bottom: 2rem;
`;
