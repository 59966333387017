import React from 'react';
import PropTypes from 'prop-types';

import { Container, ErrorText, InputStyled, Label } from './styles';

function Input({
  as,
  disabled,
  error,
  errorText,
  handleBlur,
  handleChange,
  id,
  label,
  options,
  placeholder,
  type,
  value,
}) {
  return (
    <Container>
      {label !== 'none' && (
        <Label htmlFor={id} error={error}>
          {label || placeholder}
        </Label>
      )}
      <InputStyled
        autoComplete="new-password"
        disabled={disabled}
        error={error ? 1 : 0}
        id={id}
        as={as}
        name={id}
        onBlur={() => handleBlur && handleBlur()}
        placeholder={placeholder}
        type={type}
        value={value}
        {...(handleChange ? { onChange: handleChange } : {})}
      >
        {options?.length &&
          options.map(({ label: optionLabel, value: optionValue }) => (
            <option key={optionValue} value={optionValue}>
              {optionLabel}
            </option>
          ))}
      </InputStyled>
      {errorText && <ErrorText>{errorText}</ErrorText>}
    </Container>
  );
}

Input.propTypes = {
  as: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Input;
