import React from 'react';
import PropTypes from 'prop-types';

import { Container, ErrorText, InputStyled, Label } from '../Input/styles';

function Select({
  disabled,
  error,
  errorText,
  handleBlur,
  handleChange,
  id,
  label,
  options,
  placeholder,
  value,
}) {
  return (
    <Container>
      {label !== 'none' && (
        <Label htmlFor={id} error={error}>
          {label || placeholder}
        </Label>
      )}
      <InputStyled
        disabled={disabled}
        error={error ? 1 : 0}
        id={id}
        name={id}
        onBlur={() => handleBlur && handleBlur()}
        placeholder={placeholder}
        as="select"
        value={value}
        {...(handleChange ? { onChange: handleChange } : {})}
      >
        {options?.map((opt) => (
          <option key={opt.label} value={opt.value}>
            {opt.label}
          </option>
        ))}
      </InputStyled>
      {errorText && <ErrorText>{errorText}</ErrorText>}
    </Container>
  );
}

Select.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape),
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Select;
